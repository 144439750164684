import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {Row, Col} from 'reactstrap';
import {useQuery} from 'react-query';
import Axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

import CategoryListItem from './categoryListItem';
import NewCategory from './newCategory';
import Divider from "@mui/material/Divider";

function ManageCategories(props) {
    const {
        isLoading: categoriesLoading,
        data: categoriesData,
        refetch: categoriesRefetch
    } = useQuery('categories', async () => {
        const response = await Axios.get(`${process.env.REACT_APP_API_URL}/products/categories`);

        return response?.data;
    }, {
        refetchOnWindowFocus: false,
    });

    return (
        <Container>
            <Row>
                <Col>
                    <Typography variant="h3" className="mb-3">
                        Manage Categories
                        {(categoriesLoading) && (
                            <>
                                {' '}
                                <CircularProgress size={30}/>
                            </>
                        )}
                    </Typography>
                    <Divider sx={{
                        backgroundImage: "linear-gradient(to right, #61c2ee, #b9d76b)",
                        borderBottomWidth: 3,
                        borderRadius: 1,
                        marginBottom: 2
                    }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Typography variant="h6" className="mb-3 mt-4">
                        Add a New Category
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col>
                    <NewCategory refetch={categoriesRefetch}/>
                </Col>
            </Row>
            {categoriesData?.length > 0 && (
                <>
                    <Row>
                        <Col>
                            <Typography variant="h6" className="mb-1 mt-5">
                                Current Categories
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Box sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                                <List>
                                    {categoriesData.map((category) => (
                                        <CategoryListItem category={category} refetch={categoriesRefetch}
                                                          key={category.id}/>
                                    ))}
                                </List>
                            </Box>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
};

export default ManageCategories;
