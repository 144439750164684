import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Toolbar, IconButton, Drawer, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import ExploreIcon from '@material-ui/icons/Explore';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import FilterListIcon from '@mui/icons-material/FilterList';
import AssessmentIcon from '@mui/icons-material/Assessment';

import './sidenav.css';
import logo from '../../static/logo.png';
import authHandler from '../Auth/authHandler';
import { APP_LOGOUT } from '../../helpers/authHelpers';

const COMPRESSED_MENU_ON_SIZE = 900;

const useStyles = makeStyles((theme, drawerWidth) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    menuButton: {
        textTransform: "none !important",
        color:"rgb(7, 77, 130)"
    },
    "&:hover": {
        menuButton: {
            backgroundColor: 'transparent'
        }
    },
    drawerPaper: {
        width: drawerWidth,
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}));

const DONOR_MENU_ITEMS = [
    {
        label: 'Activity',
        href: '/dashboard/activity',
        icon: <AssessmentIcon />
    },
    {
        label: 'Manage Users',
        href: '/dashboard/users',
        icon: <GroupIcon />
    },
    {
        label: 'Manage Categories',
        href: '/dashboard/categories',
        icon: <FilterListIcon />
    },
    {
        label: 'Explore Products',
        href: '/',
        icon: <ExploreIcon />
    },
    {
        label: 'Your Profile',
        href: '/dashboard/profile',
        icon: <AccountBoxIcon />
    },
]

const CHARITY_MENU_ITEMS = [
    {
        label: 'Profile',
        href: '/dashboard/0',
        icon: <AccountBoxIcon />
    },
    {
        label: 'Claimed',
        href: '/dashboard/1',
        icon: <ShopTwoIcon />
    },
    {
        label: 'Bookmarked',
        href: '/dashboard/2',
        icon: <BookmarksIcon />
    }
];

const charity = 'charity';

function SideNav(props) {

    const [state, setState] = useState({
        mobileView: false,
        menuOpen: false
    });

    const { mobileView, menuOpen } = state;

    const classes = useStyles();
    var menuItems = DONOR_MENU_ITEMS;
    if (props.actor === charity) {
        menuItems = CHARITY_MENU_ITEMS;
    }

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < COMPRESSED_MENU_ON_SIZE
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

    }, []);

    const compressedMenu = () => {

        const handleCompressedMenuOpen = () =>
            setState((prevState) => ({
                ...prevState, menuOpen: true
            })
            );

        const handleCompressedMenuClose = () =>
            setState((prevState) => ({
                ...prevState, menuOpen: false
            })
            );

        return (
            <div>
                <Toolbar >
                    <Row>
                        <Col xs="1">
                            <IconButton
                                edge="start"
                                className={classes.menuMobile}
                                color="black"
                                aria-label="menu"
                                onClick={handleCompressedMenuOpen}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="left" open={menuOpen} onClose={handleCompressedMenuClose}>
                                <Divider />
                                <List>
                                    {menuItems.map((val, index) => (
                                        <ListItem button key={val.label}>
                                            <Button to={val.href}
                                                component={Link}
                                                color="inherit"
                                                className={classes.menuButton + " text-center"}
                                                style={{ backgroundColor: 'transparent' }}
                                                key={val.label} disableRipple>
                                                <ListItemIcon>{val.icon}</ListItemIcon>
                                                <ListItemText primary={val.label} />
                                            </Button>
                                        </ListItem>
                                    ))}
                                </List>
                                <Divider />
                                <div style={{ marginTop: "20px" }}>
                                    <List >
                                        <ListItem onClick={() => {
                                                authHandler.logout(() => {
                                                    props.history.push('/');
                                                })
                                            }} button key={"Logout"}>

                                            <Button
                                                color="inherit"
                                                className={classes.menuButton + " text-center"}
                                                style={{ backgroundColor: 'transparent' }}
                                                key={"Logout"} disableRipple>
                                                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                                <ListItemText primary={"Logout"} />
                                            </Button>
                                        </ListItem>
                                    </List>
                                </div>
                            </Drawer>
                        </Col>
                        <Col xs={{ size: "2", offset: "4" }} className="pb-2">
                            <Link to="/"><img src={logo} className="img-fluid" alt="logo" />
                            </Link></Col>
                    </Row>
                </Toolbar>
            </div>
        )
    }

    const fullMenu = () => {
        return (
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <Typography variant="button" style={{ marginTop: 90, marginLeft: 26, marginBottom: 5 }}>
                    Dashboard
                </Typography>
                <Divider />
                <List>
                    {menuItems.map((val, index) => (
                        <ListItem button key={val.label}>
                            <Button to={val.href}
                                component={Link}
                                color="inherit"
                                className={classes.menuButton + " text-center"}
                                style={{ backgroundColor: 'transparent' }}
                                key={val.label} disableRipple>
                                <ListItemIcon>{val.icon}</ListItemIcon>
                                <ListItemText primary={val.label} />
                            </Button>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List >
                    <ListItem button key={"Logout"}>
                        <Button onClick={() => APP_LOGOUT()}
                            color="inherit"
                            className={classes.menuButton + " text-center"}
                            style={{ backgroundColor: 'transparent' }}
                            key={"Logout"} disableRipple>
                            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                            <ListItemText primary={"Logout"} />
                        </Button>
                    </ListItem>
                </List>

            </Drawer>);
    };

    return (
        mobileView ? compressedMenu() : fullMenu()
    )
}

export default SideNav;