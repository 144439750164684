import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from 'react-query';
import Axios from 'axios';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Typography } from '@mui/material';
import Divider from "@mui/material/Divider";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import ProductList from './productsList'
import './products.css';
import { IS_LOGGEDIN, userIsAdmin, userIsDonor } from '../../helpers/authHelpers';
import UserContext from '../../contexts/UserContext';
import NewProduct from '../../components/Dashboard/NewProduct/newProduct';

// TODO: Add filters space, fetch N products at time and display them!
// discuss with team to make api as - fetch N products for page X
// Page 1 - Products 1 to N
// Page 2 - Products N+1 to 2N+1
// ...

function Products() {
    const [user] = useContext(UserContext);
    const [selectedCategories, setSelectedCategories] = useState(new Set());
    const [searchQuery, setSearchQuery] = useState('');
    const [filterParams, setFilterParams] = useState({});
    const [availabilityFilter, setAvailabilityFilter] = useState(null);

    const {
        isLoading: productsLoading,
        data: products,
        refetch: refetchProducts
    } = useQuery(['products', filterParams], async () => {
        const response =  await Axios.get(`${process.env.REACT_APP_API_URL}/products`, {
            params: filterParams,
        });

        return response?.data;
    }, {
        refetchOnWindowFocus: false,
    });

    const {
        data: categories
    } = useQuery('categories', async () => {
        const response =  await Axios.get(`${process.env.REACT_APP_API_URL}/products/categories`);

        return response?.data;
    }, {
        refetchOnWindowFocus: false,
    });

    const handleCategoryCheckbox = (e, isChecked) => {
        const newSelectedCategories = new Set(selectedCategories);
        
        if (isChecked) {
            newSelectedCategories.add(e.target.value)
        } else {
            newSelectedCategories.delete(e.target.value)
        }

        setSelectedCategories(newSelectedCategories);
    }

    const updateParams = () => {
        const newFilterParams = {};
        newFilterParams.size = 999;

        if (selectedCategories.size > 0) {
            newFilterParams.categoryId = Array.from(selectedCategories).join(',');
        }

        if (searchQuery.length > 0) {
            newFilterParams.name = searchQuery;
        }

        if (availabilityFilter?.length > 0) {
            newFilterParams.status = availabilityFilter;
        }

        setFilterParams(newFilterParams);
    };

    useEffect(() => {
        updateParams();
    }, [availabilityFilter]);

    useEffect(() => {
        updateParams();
    }, [selectedCategories]);

    const searchProducts = (e) => {
        e.preventDefault();
        updateParams();
    };

    const clearSearch = () => {
        setSearchQuery('');
    };

    useEffect(() => {
        setTimeout(() => {
            updateParams();
        }, 1000);
      }, [searchQuery]);

    const updateAvailabilityFilter = (e) => {
        if (e.target.value) {
            setAvailabilityFilter(e.target.value);
        } else {
            setAvailabilityFilter(null);
        }
    };

    return (
        <>
            {IS_LOGGEDIN && (userIsDonor(user) || userIsAdmin(user)) && (
                <NewProduct />
            )}
            <Container className="mt-5">
                <Row>
                    <Col md={3}>
                        <Typography variant="h4" gutterBottom sx={{ fontSize: 28 }}>
                            Filter by Category
                        </Typography>
                        <Divider sx={{ backgroundColor: "primary.light", borderBottomWidth: 3, borderRadius: 1, marginBottom: 2 }}/>
                        {/*<Divider sx={{ backgroundImage: "linear-gradient(to right, #61c2ee, #b9d76b)", borderBottomWidth: 3, borderRadius: 1, marginBottom: 2 }} />*/}
                        {categories?.length > 0 && (
                            <FormControl component="fieldset">
                                <FormGroup>
                                    {categories.map((category) => (
                                        <FormControlLabel
                                            value={category.id}
                                            control={<Checkbox onChange={handleCategoryCheckbox} color={"secondary"}/>}
                                            label= {category.name}
                                            key={category.id}
                                            style={{ marginBottom: 0 }}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        )}
                        <Typography variant="h4" gutterBottom style={{ marginTop: 30 }} sx={{ fontSize: 28 }}>
                            Search
                        </Typography>
                        <Divider sx={{ backgroundColor: "primary.light", borderBottomWidth: 3, borderRadius: 1, marginBottom: 2 }}/>
                        {/*<Divider sx={{ backgroundImage: "linear-gradient(to right, #61c2ee, #b9d76b)", borderBottomWidth: 3, borderRadius: 1, marginBottom: 2 }}/>*/}
                        <Paper
                            component="form"
                            onSubmit={searchProducts}
                            sx={{ p: '2px 8px', width: '100%', display: 'flex' }}
                            >
                                <InputBase
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    value={searchQuery}
                                    type="text"
                                    sx={{ flex: 1 }}
                                    placeholder="Search products"
                                    endAdornment={
                                        searchQuery.length > 0 && (
                                            <IconButton onClick={clearSearch}>
                                                <ClearOutlinedIcon />
                                            </IconButton>
                                        )
                                    }
                                />
                                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                        </Paper>
                        <Typography variant="h4" gutterBottom style={{ marginTop: 30 }} sx={{ fontSize: 28 }}>
                            Filter by Availability
                        </Typography>
                        <Divider sx={{ backgroundColor: "primary.light", borderBottomWidth: 3, borderRadius: 1, marginBottom: 2 }}/>
                        {/*<Divider sx={{ backgroundImage: "linear-gradient(to right, #61c2ee, #b9d76b)", borderBottomWidth: 3, borderRadius: 1, marginBottom: 2 }}/>*/}
                        <FormControl>
                            {/* <FormLabel>Gender</FormLabel> */}
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={availabilityFilter}
                                onChange={updateAvailabilityFilter}
                            >
                                <FormControlLabel value={null} control={<Radio />} label="All" style={{ marginBottom: 0 }} />
                                <FormControlLabel value="1" control={<Radio />} label="Available" style={{ marginBottom: 0 }} />
                                <FormControlLabel value="2" control={<Radio />} label="Claimed" style={{ marginBottom: 0 }} />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                    <Col md={9}>
                        {productsLoading
                            ? <CircularProgress />
                            : (
                                products?.products?.length > 0
                                    ? <ProductList products={products.products} refetch={refetchProducts} />
                                    : <Typography variant="subtitle1">Sorry, no products found.</Typography>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Products;