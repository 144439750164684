import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, MenuItem } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

import classes from './navbar.module.css';
import Greet from './greet.js';
import logo from '../../static/logo.png';
import UserContext from '../../contexts/UserContext';

const COMPRESSED_MENU_ON_SIZE = 900;

export default function NavBar() {
    const [state, setState] = useState({
        mobileView: false,
        menuOpen: false
    });
    
    const [ user ] = useContext(UserContext);
    const { mobileView, menuOpen } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < COMPRESSED_MENU_ON_SIZE
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

    }, []);

    const compressedMenu = () => {

        const handleCompressedMenuOpen = () =>
            setState((prevState) => ({
                ...prevState, menuOpen: true
            })
            );

        const handleCompressedMenuClose = () =>
            setState((prevState) => ({
                ...prevState, menuOpen: false
            })
            );

        return (
            <Toolbar>
                <IconButton
                    edge="start"
                    className={classes.menuMobile}
                    color="black"
                    aria-label="menu"
                    onClick={handleCompressedMenuOpen}>
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={menuOpen} onClose={handleCompressedMenuClose}>
                    <div className={classes.drawerContainer}>
                        {getCompressedMenus()}
                        {user
                            ? <div><Greet color="#000" marginLeft="0px" /></div>
                            : (<ButtonGroup disableElevation orientation="vertical">
                                <Button style={{ color: '#074d82' }} variant="text" component={Link} to='/auth'>Register</Button>
                                <Button style={{ background: '#6cb545' }} variant="contained" component={Link} to='/auth/1'>Sign In</Button>
                            </ButtonGroup>)}
                    </div>
                </Drawer>
                <Col xs={{ size: "2", offset: "4" }} className="pb-2">
                    <Link to="/"><img src={logo} className="img-fluid" alt="logo" />
                    </Link>
                </Col>
            </Toolbar>
        )
    }

    const fullMenu = () => {
        return (
            <Toolbar className={classes.toolbar}>
                <Col xs="1" sm="1" lg="1">
                    <Link to="/"><img src={"https://firebasestorage.googleapis.com/v0/b/upcycle4good-f58a3.appspot.com/o/email-images%2FU4GLogo.JPG?alt=media&token=69297a6a-8904-4478-988f-152e1f313f6d"}
                                      className={classes.logo}
                                      alt="logo"/>
                    </Link>
                </Col>
                <Col xs="8" sm="8" lg="8">
                    <div>{getButtonMenus()}</div>
                </Col>

                <Col xs="2" sm="2" lg="2">
                    {user
                        ? (
                            <Greet color="#000" />
                        ) : (
                            <ButtonGroup disableElevation>
                                <Button style={{ color: '#074d82' }} variant="text" component={Link} to='/auth'>Register</Button>
                                <Button style={{ background: '#6cb545' }} variant="contained" component={Link} to='/auth/1'>Sign In</Button>
                            </ButtonGroup>
                        )
                    }
                </Col>
            </Toolbar>
        );
    };

    const getButtonMenus = () => {
        return (
            <div className={classes.menuContainer}>
                <a className={classes.menuButton} href="https://upcycle4good.org/">
                    Home
                </a>
                <Link className={classes.menuButton} to='/'>
                    Products
                </Link>
                <Link to='/how-to' className={classes.menuButton}>
                    How To
                </Link>
                <a href="https://upcycle4good.org/frequently-asked-questions-faqs/" className={classes.menuButton}>
                    FAQs
                </a>
                <a href="https://upcycle4good.org/our-mission-in-action/" className={classes.menuButton}>
                    About Us
                </a>
                <a href="https://upcycle4good.org/contact/" className={classes.menuButton}>
                    Contact
                </a>
            </div>
        );
    };

    const getCompressedMenus = () => {
        return (
            <>
                <MenuItem>
                    <a className={classes.menuButton} href="https://upcycle4good.org/">
                        Home
                    </a>
                </MenuItem>
                <MenuItem>
                    <Link className={classes.menuButton} to='/'>
                        Products
                    </Link>
                </MenuItem>
                <MenuItem>
                    <Link to='/how-to' className={classes.menuButton}>
                        How to
                    </Link>
                </MenuItem>
                <MenuItem>
                    <a href="https://upcycle4good.org/frequently-asked-questions-faqs/" className={classes.menuButton}>
                        FAQs
                    </a>
                </MenuItem>
                <MenuItem>
                    <a  href="https://upcycle4good.org/our-mission-in-action/" className={classes.menuButton}>
                        About Us
                    </a>
                </MenuItem>
                <MenuItem>
                    <a href="https://upcycle4good.org/our-mission-in-action/" className={classes.menuButton}>
                        Contact
                    </a>
                </MenuItem>
            </>
        );
    };

    return (
        <div className={classes.nav_container}>
            <AppBar position="relative" style={{ background: '#fff', boxShadow: "none", zIndex: 1220 }}>
                {mobileView ? compressedMenu() : fullMenu()}
            </AppBar>
        </div>
    )
};
