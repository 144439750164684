import React from "react";
import { Container, Row, Col } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import styles from "./contactUs.module.css";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";

class ContactUs extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phoneNum: "",
      message: "",
      messageSent: false
    };
  }

  // componentDidMount() {
  //   this.setState({ isLoading: true });

  //   fetch(API_URL)
  //     .then(res => res.json())
  //     .then((result) => { this.setState({ products: result, isLoading: false }) },
  //       (error) => { console.log(error) });
  // }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit = event => {
    event.preventDefault();
    console.log('here')

    const URL = `${process.env.REACT_APP_API_URL}/public/contactUs/new/`
    const data = {
      name: this.state.name,
      email: this.state.email,
      phoneNum: this.state.phoneNum,
      message: this.state.message,
    }

    fetch(URL, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(response => {this.setState({messageSent:true}); console.log("Success", response)})
    .catch(error => console.log("Error", error));
  }

  render() {

    if(this.state.messageSent){
      return (
        <div>
        <Container fluid>
          <Row>
            <Col
              xs="12"
              lg="6"
              className={"px-2 " + styles.banner}
            ><Typography variant="h5" component="h2" align="justify" className={styles.bannerText + " py-3"}>
                Preserve the environment.<br />Identifying Opportunities.<br />Encourage change by offering alternatives
            </Typography></Col>
            {/* </Row> */}
            {/* <Row> */}
            <Col lg="6" className="px-5" style={{ height: "77vh", marginTop: "5%" }}>
              <Row>
                Your message has been received, we will reach out soon.
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      )
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col
              xs="12"
              lg="6"
              className={"px-2 " + styles.banner}
            ><Typography variant="h5" component="h2" align="justify" className={styles.bannerText + " py-3"}>
                Preserve the environment.<br />Identifying Opportunities.<br />Encourage change by offering alternatives
            </Typography></Col>
            {/* </Row> */}
            {/* <Row> */}
            <Col lg="6" className="px-5" style={{ height: "77vh", marginTop: "5%" }}>
              <Row>
                <Col>
                  <h1 className={styles.formMainText}>Contact us</h1>
                </Col>
              </Row>
              <Row className={styles.formFields}>
                <Col xs="12" md="12" lg="12" className={styles.formFieldColumn}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    InputProps={{
                      className: styles.formTextField,
                    }}
                    className={styles.textField}
                    name="name"
                    onChange={this.handleUpdate}
                  />
                </Col>
              </Row>
              <Row className={styles.formFields}>
                <Col xs="12" md="12" lg="12" className={styles.formFieldColumn}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    InputProps={{
                      className: styles.formTextField,
                    }}
                    name="email"
                    className={styles.textField}
                    onChange={this.handleUpdate}
                  />
                </Col>
              </Row>
              <Row className={styles.formFields}>
                <Col xs="12" md="12" lg="12" className={styles.formFieldColumn}>
                  <TextField
                    id="outlined-basic"
                    label="Phone number"
                    variant="outlined"
                    InputProps={{
                      className: styles.formTextField,
                    }}
                    className={styles.textField}
                    name="phoneNum"
                    onChange={this.handleUpdate}
                  />
                </Col>
              </Row>
              <Row className={styles.formFields}>
                <Col xs="12" md="12" lg="12" className={styles.formFieldColumn}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Message"
                    variant="outlined"
                    InputProps={{
                      className: {
                        root: styles.formTextField,
                        focused: styles.focused,
                        notchedOutline: styles.notchedOutline,
                      },
                    }}
                    className={styles.textField}
                    rows={4}
                    multiline
                    rowsMax={4}
                    name="message"
                    onChange={this.handleUpdate}
                  />
                </Col>
              </Row>
              <Row className={styles.submitButtonRow}>
                <Button variant="contained" className={styles.submitButton} onClick={this.handleSubmit} type="submit">
                  Submit
            </Button>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ContactUs;
