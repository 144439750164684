import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {Row, Col} from 'reactstrap';
import {useQuery} from 'react-query';
import Axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

import {getAccessToken} from '../../../helpers/authHelpers';
import UsersTable from './UserTable';
import Divider from "@mui/material/Divider";

function ManageUsers() {
    const accessToken = getAccessToken();

    const {
        isLoading: approvedUsersLoading,
        data: approvedUsersData,
        refetch: approvedUsersRefetch
    } = useQuery('approvedUsers', async () => {
        const response = await Axios.get(`${process.env.REACT_APP_API_URL}/users?isActive=true&isApproved=true`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        return response?.data;
    }, {
        refetchOnWindowFocus: false,
    });

    const {
        isLoading: inactiveUsersLoading,
        data: inactiveUsersData,
        refetch: inactiveUsersRefetch
    } = useQuery('inactiveUsers', async () => {
        const response = await Axios.get(`${process.env.REACT_APP_API_URL}/users?isActive=false`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        return response?.data;
    }, {
        refetchOnWindowFocus: false,
    });

    const {
        isLoading: pendingUsersLoading,
        data: pendingUsersData,
        refetch: pendingUsersRefetch
    } = useQuery('pendingUsers', async () => {
        const response = await Axios.get(`${process.env.REACT_APP_API_URL}/users?isPending=true`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        return response?.data;
    }, {
        refetchOnWindowFocus: false,
    });

    const {
        isLoading: deniedUsersLoading,
        data: deniedUsersData,
        refetch: deniedUsersRefetch
    } = useQuery('deniedUsers', async () => {
        const response = await Axios.get(`${process.env.REACT_APP_API_URL}/users?isApproved=false&isPending=false`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        return response?.data;
    }, {
        refetchOnWindowFocus: false,
    });

    const refetch = () => {
        approvedUsersRefetch();
        pendingUsersRefetch();
        deniedUsersRefetch();
        inactiveUsersRefetch();
    };

    return (
        <Container>
            <Row>
                <Col>
                    <Typography variant="h3" className="mb-3">
                        Manage Users
                        {(deniedUsersLoading || approvedUsersLoading || pendingUsersLoading || deniedUsersLoading) && (
                            <>
                                {' '}
                                <CircularProgress size={30}/>
                            </>
                        )}
                    </Typography>
                    <Divider sx={{
                        backgroundImage: "linear-gradient(to right, #61c2ee, #b9d76b)",
                        borderBottomWidth: 3,
                        borderRadius: 1,
                        marginBottom: 4
                    }}/>
                </Col>
            </Row>

            {!pendingUsersLoading && pendingUsersData?.users?.length > 0 && (
                <Row>
                    <Col>
                        <Typography variant="h5" className="mb-3">
                            Pending Users {!pendingUsersLoading && (<span>({pendingUsersData?.users?.length})</span>)}
                        </Typography>
                        {pendingUsersData?.users?.length > 0 && (
                            <UsersTable users={pendingUsersData} refetch={refetch} type="pending"/>
                        )}
                    </Col>
                </Row>
            )}

            {!approvedUsersLoading && approvedUsersData?.users?.length > 0 && (
                <Row style={{marginTop: 40}}>
                    <Col>
                        <Typography variant="h5" className="mb-3">
                            Approved Users {!approvedUsersLoading && (
                            <span>({approvedUsersData?.users?.length})</span>)}
                        </Typography>
                        {approvedUsersData?.users?.length > 0 && (
                            <UsersTable users={approvedUsersData} refetch={refetch} type="approved"/>
                        )}
                    </Col>
                </Row>
            )}

            {!deniedUsersLoading && deniedUsersData?.users?.length > 0 && (
                <Row style={{marginTop: 40}}>
                    <Col>
                        <Typography variant="h5" className="mb-3">
                            Denied Users {!deniedUsersLoading && (<span>({deniedUsersData?.users?.length})</span>)}
                        </Typography>
                        {deniedUsersData?.users?.length > 0 && (
                            <UsersTable users={deniedUsersData} refetch={refetch} type="denied"/>
                        )}
                    </Col>
                </Row>
            )}

            {!inactiveUsersLoading && inactiveUsersData?.users?.length > 0 && (
                <Row style={{marginTop: 40}}>
                    <Col>
                        <Typography variant="h5" className="mb-3">
                            Inactive Users {!inactiveUsersLoading && (
                            <span>({inactiveUsersData?.users?.length})</span>)}
                        </Typography>
                        {inactiveUsersData?.users?.length > 0 && (
                            <UsersTable users={inactiveUsersData} refetch={refetch} type="inactive"/>
                        )}
                    </Col>
                </Row>
            )}

        </Container>
    );
};

export default ManageUsers;
