import React from 'react';
import { useMutation } from 'react-query';
import Axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';

import { getAccessToken } from '../../../helpers/authHelpers';

function CategoryListItem({ category, refetch }) {

    const mutation = useMutation(((data) => {
        const accessToken = getAccessToken();

        return Axios.delete(`${process.env.REACT_APP_API_URL}/products/categories/${data.categoryId}`, 
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }), {
        onSettled: () => {
            refetch();
        },
        onError: (e) => {
            console.error(e);
        },
    });
            
    return (
        <>
            <ListItem
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => mutation.mutate({ categoryId: category.id })}>
                        {mutation.isLoading ? <CircularProgress size={25} /> : <DeleteIcon />}
                    </IconButton>
                }
            >
                <ListItemText
                    primary={category.name}
                />
            </ListItem>
            <Divider />
        </>            
    );
};

export default CategoryListItem;
