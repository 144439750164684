import React, {useContext, useState} from 'react';
import Axios from 'axios';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { setAccessToken } from '../../helpers/authHelpers';

import UserContext from '../../contexts/UserContext';
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
      width: "20%",
      height: "auto",
      display: "inline"
  },
}));

export default function SignIn(props) {

  const [user, setUser] = useContext(UserContext);
  const [alertOpen, setAlertOpen] = useState(false)
  const navigate = useNavigate();

  const handleSubmit = (event, props) => {
    event.preventDefault();
    const URL = `${process.env.REACT_APP_API_URL}/sign-in`
    const data = {
      email: document.getElementById('email').value,
      password: document.getElementById('password').value
    }
  
    fetch(URL, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify(data)
    }).then(response => {
            if(response.status===200)
              return response.json()
            else return null
        })
        .then(parsedResponse => {
          if(parsedResponse!=null) {
            setUser(parsedResponse);
            const accessToken = parsedResponse.accessToken;
            setAccessToken(accessToken);
            if(parsedResponse.accessToken) {
              const decodedToken = JSON.parse( Buffer.from((accessToken.split(".")[1]), 'base64') );
              Axios.get(`${process.env.REACT_APP_API_URL}/users/${decodedToken.sub}`, {
                  headers: {
                      'Authorization': `Bearer ${accessToken}`
                  }
              }).then((response) => {
                  setUser(response.data);
              });
            }
            navigate('/');
          } else{
            setAlertOpen(true)
            console.log('error')
          }
        })
        .catch(error =>{
            console.log(error)
        })
  };

  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      {alertOpen &&
      <Alert severity="error">Login failed. Your email or password may be incorrect, or your account
        is disabled.</Alert>
      }
      <CssBaseline />
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="secondary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx = {{ backgroundImage: "linear-gradient(to right, #074d82, #b9d76b) " }}
            color="primary"
            className={classes.submit}
            style={{ marginBottom: 20, marginTop: 10 }}
            onClick={ (e) => { handleSubmit(e, props)}}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/auth/2" variant="body2" color={"primary"}>
                {"Forgot password?"}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/auth/" variant="body2" id="creds-panel-tab-0" color={"primary"}>
                {"Don't have an account? Sign up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
