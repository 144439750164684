import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Row, Col } from "reactstrap";
import { Typography } from "@material-ui/core";

import styles from './cards.module.css';

function CardImgOnX(props) {
    return (
        <Card>
            <Row className={props.imgLeft? "" : "flex-column-reverse flex-md-row"}>
                {
                    props.imgLeft &&
                    <Col md="4" className="px-0 align-self-center">
                        <img src={props.img} alt="{props.description}" className="img-fluid" />
                    </Col>
                }
                <Col md="8">
                    <CardContent>
                        <Typography component="h3" variant="h4" className={styles.paperContainerHeading}>
                            {props.title}
                        </Typography>
                        <Typography component="h3" variant="subtitle" >
                            {props.subtitle}
                        </Typography>
                        <Typography component="p" variant="body2" className="text-justify" color="textSecondary">
                            {props.description}
                        </Typography>
                    </CardContent>
                </Col>
                {
                    !props.imgLeft &&
                    <Col md="4" className="px-0 align-self-center">
                        <img src={props.img} alt="{props.description}" className="img-fluid" />
                    </Col>
                }
            </Row>
        </Card>
    );
}

export default CardImgOnX;