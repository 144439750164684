import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col} from 'reactstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import {useMutation} from 'react-query';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import UserContext from '../../../contexts/UserContext';
import {getAccessToken, USER_ROLES_IDS} from '../../../helpers/authHelpers';

export default function Profile() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [organization, setOrganization] = useState('');
    const [password, setPassword] = useState('');
    const [alertId, setAlertId] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [user, setUser] = useContext(UserContext);

    const accessToken = getAccessToken();

    const mutation = useMutation((updatedUser => {
        return axios.put(`${process.env.REACT_APP_API_URL}/users/${user.userId}`,
            updatedUser, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }), {
        onSuccess: (res) => {
            setSuccessOpen(true);

            //
            const updatedUser = {...user};
            updatedUser.email = res.data.email;
            updatedUser.firstName = res.data.firstName;
            updatedUser.lastName = res.data.lastName;
            updatedUser.phoneNum = res.data.phoneNum;
            updatedUser.organization = res.data.organization;
            setUser(updatedUser);
        },
        onError: () => {
            setErrorOpen(true);
        },
    });

    useEffect(() => {
        if (user) {
            setFirstName(user?.firstName);
            setLastName(user?.lastName);
            setEmail(user?.email);
            setPhoneNumber(user?.phoneNum);
            setOrganization(user?.organization)
        }
    }, [user]);

    const handleSubmit = (e) => {
        e.preventDefault();

        mutation.mutate({
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "password": password || null,
            "phoneNum": phoneNumber,
            "organization": organization
        });
    };

    function minMaxLength(text, minLength, maxLength) {
        let result = text.length < minLength;
        if(maxLength)
            result = result || text.length > maxLength;
        return !result;
    }

    function validEmail(text) {
        const regex = RegExp(
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        );

        return regex.test(text);
    }

    function handleChange (e){
        const { id, value } = e.target;

        switch (id) {
            case 'firstName':
                if (!value || !minMaxLength(value, 2, 45)) {
                    setAlertMessage("First name should be at least 2 characters")
                    setAlertId('firstName')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                }
                break;
            case 'lastName':
                if (!value || !minMaxLength(value, 2, 45)) {
                    setAlertMessage("Last name should be at least 2 characters")
                    setAlertId('lastName')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                }
                break;
            case 'email':
                if (!value || !validEmail(value) || !minMaxLength(value, 0, 50)) {
                    setAlertMessage("Email address is invalid")
                    setAlertId('email')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                }
                break;
            case 'phoneNumber':
                if (!minMaxLength(value, 0, 20)) {
                    setAlertMessage("Phone number cannot exceed 20 characters")
                    setAlertId('phoneNumber')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    if (value === '') {
                        setPhoneNumber(null);
                    }
                }
                break;
            case 'organization':
                if (!value) {
                    setAlertMessage("Organization cannot be blank")
                    setAlertId('organization')
                } else if (!minMaxLength(value, 0, 50)) {
                    setAlertMessage("Organization cannot exceed 50 characters")
                    setAlertId('organization')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                }
                break;
            case 'password':
                if (value && !minMaxLength(value, 6, 40)) {
                    setAlertMessage("Password should be at least 6 characters")
                    setAlertId('password')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                }
                break;
            default:
                break;
        }
    }

    return (
        <Container>
            <Row className="mb-3">
                <Col>
                    <Typography variant="h3" gutterBottom>
                        Your Profile
                    </Typography>
                    <Divider sx={{
                        backgroundImage: "linear-gradient(to right, #61c2ee, #b9d76b)",
                        borderBottomWidth: 3,
                        borderRadius: 1,
                        marginBottom: 2
                    }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Typography variant="h5">
                        Edit Details
                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Paper variant="outlined" className="p-4">
                        <Grid container rowSpacing={3} columnGap={2}>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    value={firstName}
                                    error={alertId === "firstName"}
                                    onBlur={handleChange}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    value={lastName}
                                    error={alertId === "lastName"}
                                    onBlur={handleChange}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    type="email"
                                    value={email}
                                    error={alertId === "email"}
                                    onBlur={handleChange}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    required={false}
                                    fullWidth
                                    id="phoneNumber"
                                    label="Phone Number"
                                    type="phone"
                                    value={phoneNumber}
                                    error={alertId === "phoneNumber"}
                                    onBlur={handleChange}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    required
                                    fullWidth
                                    id="organization"
                                    label="Organization"
                                    type="organization"
                                    value={organization}
                                    error={alertId === "organization"}
                                    onBlur={handleChange}
                                    onChange={(e) => setOrganization(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    required={false}
                                    fullWidth
                                    id="password"
                                    label="New Password (Optional)"
                                    type="password"
                                    value={password}
                                    error={alertId === "password"}
                                    onBlur={handleChange}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Role(s)
                                </Typography>
                                <Divider/>
                                <div className="mt-2">
                                    {user?.roles.map((role, index) => (
                                        <span key={index}>
                                        {USER_ROLES_IDS[role.typeId]?.label}
                                            {index < user?.roles.length - 1 ? ', ' : ''}
                                    </span>
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Stack spacing={2} direction="row" justifyContent="flex-end" className="mt-4 mb-5 w-100">
                        {/* TODO: what happens when cancel - send back to another page, or clear edits? */}
                        <Button
                            variant="text"
                            size="medium"
                            onClick={() => mutation.reset()}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={handleSubmit}
                            variant="contained"
                            size="medium"
                            loading={mutation.isLoading}
                        >
                            Save
                        </LoadingButton>
                    </Stack>
                    <Snackbar open={alertId}>
                        <Alert severity="error" sx={{width: '100%'}}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={successOpen} autoHideDuration={3000} onClose={() => setSuccessOpen(false)}>
                        <Alert severity="success" sx={{width: '100%'}}>
                            Changes saved successfully!
                        </Alert>
                    </Snackbar>
                    <Snackbar open={errorOpen} autoHideDuration={3000} onClose={() => setErrorOpen(false)}>
                        <Alert severity="error" sx={{width: '100%'}}>
                            There was an error, please try again.
                        </Alert>
                    </Snackbar>
                </Col>
            </Row>
        </Container>
    );
};