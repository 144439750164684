export const USER_ROLES = {
    ROLE_ADMIN: {
        id: 3,
        key: "ROLE_ADMIN",
        label: "Admin"
    },
    ROLE_DONOR: { 
        id: 2,
        key: "ROLE_DONOR",
        label: "Donor"
    },
    ROLE_CHARITY: { 
        id: 1,
        key: "ROLE_CHARITY",
        label: "Charity"
    },
};

export const USER_ROLES_IDS = {
    1: { 
        key: 1,
        label: "Charity"
    },
    2: { 
        key: 2,
        label: "Donor"
    },
    3: { 
        key: 3,
        label: "Admin"
    },
};

export function APP_LOGOUT() {
    localStorage.removeItem("u4gApiAccessToken");
    PAGE_REFRESH();
}

export function PAGE_REFRESH() {
    window.location.reload();
}

export const setAccessToken = (token) => {
    localStorage.setItem('u4gApiAccessToken', token);
}

export const getAccessToken = () => {
    return localStorage.getItem('u4gApiAccessToken');
}

export function IS_LOGGEDIN() {
    return getAccessToken() || null;
}

export function userIsAdmin(user) {
    if (!user?.roles) return null;

    const admin = user.roles.find((role) => role.typeId === USER_ROLES.ROLE_ADMIN.id);

    return Boolean(admin);
}

export function userIsPending(user) {
    if (!user?.roles) return null;

    const pending = user.roles.find((role) => role.createdAt && !role.approvedAt);

    return Boolean(pending);
}

export function userIsDonor(user) {
    if (!user?.roles) return null;

    const donor = user.roles.find((role) => (
        role.typeId === USER_ROLES.ROLE_DONOR.id &&
        role.approvedAt !== null
    ));

    return Boolean(donor);
}

export function userIsCharity(user) {
    if (!user?.roles) return null;

    const charity = user.roles.find((role) => (
        role.typeId === USER_ROLES.ROLE_CHARITY.id &&
        role.approvedAt !== null
    ));

    return Boolean(charity);
}
