import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Container, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';

import SignIn from './signIn';
import Register from './register';
import classes from './auth.module.css'
import logo from '../../static/logo.png'
import ResetPassword from "./resetPassword";

function AuthPanel(props) {

    const { children, value, index, ...other } = props;
    return (
        <div
            hidden={value !== index}
            id={`creds-panel-tabpanel-${index}`}
            aria-labelledby={`creds-panel-tab-${index}`}
            {...other}
        >
            { value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

AuthPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function propsIndexer(index) {
    return {
        id: `creds-panel-tab-${index}`,
        'aria-controls': `creds-panel-tabpanel-${index}`,
    };
}

function Auth(props) {
    let { authPage } = useParams();
    if(authPage == null) {
        authPage = "0";
    }
    const [value, setValue] = useState(parseInt(authPage));

    const updateValue = (event, newValue) => { setValue(newValue); };

    return (
        <div>
            <Container>
                <Row>
                    <Col md={{ size: "6", offset: "3" }}>
                        <img src={logo} className={classes.logo} alt="logo" />
                    </Col>
                    <Col md={{ size: "6", offset: "3" }} xs={{ size: "10", offset: "1" }} className={"border px-0 mt-5"}>
                        <AppBar position="static" style={{ 'backgroundColor': 'white', marginTop: "2%" }} elevation={0}>
                            <Tabs
                                value={value}
                                onChange={updateValue}
                                aria-label="cred tabs"
                                centered
                                classes={{ indicator: classes.indicatorColor }}
                                indicatorColor={"secondary"}
                            >
                                <Tab label="Register" {...propsIndexer(0)} style={{ color: '#4d555b', marginRight: 10 }} />
                                <Tab label="Sign In" {...propsIndexer(1)} style={{ color: '#4d555b', marginLeft: 10 }} />
                            </Tabs>
                        </AppBar>
                        <AuthPanel value={value} index={0}>
                            <Register {...props} />
                        </AuthPanel>
                        <AuthPanel value={value} index={1}>
                            <SignIn {...props} />
                        </AuthPanel>
                        <AuthPanel value={value} index={2}>
                            <ResetPassword {...props} />
                        </AuthPanel>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Auth;