import React, { useEffect, useState, useContext} from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import './productdetail.css'
import { IS_LOGGEDIN, userIsCharity, userIsAdmin, getAccessToken } from '../../helpers/authHelpers';
import moment from 'moment';
import Axios from 'axios';
import { useQuery, useMutation } from 'react-query';

import CircularProgress from '@mui/material/CircularProgress';
import logo from '../../static/image-placeholder.png';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Avatar from '@mui/material/Avatar';
import { useParams } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';

const BASE_URL = `${process.env.REACT_APP_API_URL}/products`

function ProductDetails() {
    let { productId } = useParams();
    const [hasDeadline, setHasDeadline]= useState(false);

    const [user] = useContext(UserContext);

    const {
        isLoading,
        data: product,
        refetch,
    } = useQuery(['product', productId ], async () => {
        const response =  await Axios.get(`${BASE_URL}/${productId}`);

        return response?.data;
    }, {
        refetchOnWindowFocus: false,
    });

    const productImage = (productImgurl) =>{
        return (productImgurl===null || productImgurl==='') ? logo : productImgurl;
    }

    const mutation = useMutation((() => {
        const accessToken = getAccessToken();

        const data = {
            productId: productId,
            donorUserId: product.ownerId,
            claimerUserId: user.userId
        }

        return Axios.post(`${BASE_URL}/claim/${productId}`, 
            data, {
                    headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        );
    }), {
        onSettled: () => {
            refetch();
        },
        onError: (e) => {
            console.error(e);
        },
    });
    
    const sendClaim = () => {
        mutation.mutate();
    };

    const claimProduct = (product) => {
        if(IS_LOGGEDIN()){
        // add an alert if user is trying to claim their own product
        // (in the case a charity is given a donor role)
    
            if (window.confirm("Are you sure you would like to claim this product?")) {
                sendClaim(product)
            }
        } else {
            // TODO: implement push to auth page

            // this.props.history.push({
            //     pathname: '/auth/1',
            //     state: { redirectBack: "true" }
            // })
        }
    }

    const shippingAvailable = (value) => {
        return (value==='true') ? 'Yes' : 'No'
    }

    const pickupDeadline = (value) => {
        return (value == null) ? 'N/A' : moment(value).format('MMMM Do, YYYY')
    }

    // contact info now shared through email
    // const donorCard = () => {
    //
    //     if(IS_LOGGEDIN()==='true'){
    //         return (
    //             <div>
    //                 <Row><h3 className='label'>Donor Detail</h3></Row>
    //                 <Row className='donor-card-container'>
    //                     <Avatar alt="Remy Sharp" src="" />
    //                     <Col>
    //                         <div className='donor-name'>Rohit Singh</div>
    //                         <div className='donor-email'>rsingh@asu.edu</div>
    //                         <div className='donor-phonenum'>4805432987</div>
    //                     </Col>
    //                 </Row>
    //             </div>
    //             );
    //     }
    //
    // }

    return (
        isLoading ? (
            <div>
                <Container>
                    <Row>
                        <Col className="text-center">
                        <CircularProgress style={{'color': 'rgb(7, 77, 130)'}} />
                        </Col>
                    </Row>
                </Container>
            </div>
        ) : (
            <div>
                <Container>
                    <Row>
                        <Col md={{ size: "9" }} className="text-center mt-5 mb-5">
                            <img src={productImage(product.imageUrl)} alt={product.name} className="img-fluid"/>
                        </Col>
                        <Col md="3" className="mt-5 mb-5">
                            <Row><h3 className='product-detail-title'>{product.name}</h3></Row>
                            <Row><div className='line' style={{ backgroundImage: "linear-gradient(to right, #61c2ee, #b9d76b)", height: 3, borderRadius: 2 }}/></Row>
                            <Row><h3 className='label'>Description</h3></Row>
                            <Row><h3 className='product-detail-description'>{product.description}</h3></Row>
                            <Row><div className='line'/></Row>
                            <Row className='product-detail-date-container'>
                                <DateRangeIcon/>
                                <h3 className='date posted'>Posted on: {moment(product.createdAt).format('MMMM Do, YYYY')}</h3>
                                <h3 className='date deadline'>Available until: {pickupDeadline(product.pickupDeadline)}</h3>
                            </Row>
                            <Row><div className='line'/></Row>
                            <Row className='logistic-container'>
                                <LocalShippingIcon/>
                                <h3 className='logistic product-shipping'>Shipping Available: {shippingAvailable(product.shippingAvailable)}</h3>
                                <h3 className='logistic product-location'>Location: {product.location}</h3>
                                <h3 className='logistic product-marketvalue'>Value: ${product.marketValue}</h3>
                                <h3 className='logistic product-stock'>Quantity: {product.stock}</h3>
                            </Row>
                            <Row><div className='line'/></Row>
                            {/*{donorCard()}*/}
                            {IS_LOGGEDIN && (userIsAdmin(user) || userIsCharity(user)) && (product.ownerId !== user.userId) && (
                                <Row><Button id='claim-button' className={((product.statusCode === 'AVAILABLE') ? '' : 'disable-button ') + 'mt-5 claim-button'} onClick={ e => claimProduct(product)}>{(product.statusCode === 'AVAILABLE') ? 'Claim' : 'Claimed'}</Button></Row>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    );
};

export default ProductDetails;
