import React, { useState } from 'react';
import { useMutation } from 'react-query';
import Axios from 'axios';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

import { getAccessToken } from '../../../helpers/authHelpers';

function NewCategory({ refetch }) {
    const [newCategory, setNewCategory] = useState('');
    const accessToken = getAccessToken();

    const mutation = useMutation((() => {
        
        const payload = [{ "name": newCategory}];
        return Axios.post(`${process.env.REACT_APP_API_URL}/products/categories`, 
            payload, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }), {
        onSettled: () => {
            refetch();
        },
        onSuccess: () => {
            setNewCategory('');
        },
        onError: (e) => {
            console.error(e);
        },
    });

    const handleSubmit = () => {
        if (newCategory.length > 1) {
            mutation.mutate();
        }
    };
            
    return (
        <>
            <TextField
                label="New Category"
                variant="standard"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
            />
            <LoadingButton
                className="ml-2"
                variant="outlined"
                onClick={() => handleSubmit()}
                loading={mutation.isLoading}
            >
                Add
            </LoadingButton>
        </>
    );
};

export default NewCategory;
