import React from 'react';
import Container from '@mui/material/Container';
import { Row } from 'reactstrap';
import { Outlet } from "react-router-dom";

import SideNav from './sideNav';

const drawerWidth = 230;

function Dashboard(props) {
    return (
        <>
            <SideNav drawerWidth={drawerWidth} />
            <Container className="mt-5" style={{ paddingLeft: drawerWidth + 20 }}>
                <Row>
                    <Outlet />
                </Row>              
            </Container>
        </>
    );
};

export default Dashboard;
