import {makeStyles} from "@material-ui/core/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        width: "20%",
        height: "auto",
        display: "inline"
    },
    error: {
        border: "4px red",
        borderRadius: "6px",
        borderStyle: "none solid"
    }
}));


function redirectToSignIn(props){

    props.history.push('/auth/1')
}

function minMaxLength(text, minLength, maxLength) {
    let result = text.length < minLength;
    if(maxLength)
        result = result || text.length > maxLength;
    return !result;
}

function validEmail(text) {
    const regex = RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );

    return regex.test(text);
}

function validWebsite(text) {

    if (text.length === 0) {
        return true;
    }

    const regex = RegExp(
        /^https:\/\/(.+\.)[a-z]{3}/
    );

    return regex.test(text);
}

export default function Register(props) {

    const classes = useStyles();

    const [email, setEmail]= useState(null);
    const [firstName, setFirstName]= useState(null);
    const [lastName, setLastName]= useState(null);
    const [phoneNum, setPhoneNum]= useState(null);
    const [organization, setOrganization]= useState(null);
    const [website, setWebsite]= useState(null);
    const [password, setPassword]= useState(null);
    const [confirmPassword, setConfirmPassword]= useState(null);
    const [role, setRole]= useState(null);
    const [acceptTerms, setAcceptTerms]= useState(false);

    const [alertMessage, setAlertMessage] = useState(null)
    const [alertId, setAlertId] = useState(null)

    const termsLabel = (
        <span>
            I have read and accept the Upcycle4Good <a
                href="https://upcycle4good.org/terms-and-conditions-of-use/" target="_blank"
            >Terms and Conditions
            </a> and <a
                href="https://upcycle4good.org/privacy-policy/" target="_blank"
            >Privacy Policy
            </a>
        </span>
    )

    function handleSubmit(event, props){

        event.preventDefault();
        const URL = `${process.env.REACT_APP_API_URL}/register`
        const data = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            phoneNum: phoneNum,
            organization: organization,
            website: website,
            password: password,
            confirmPassword: confirmPassword,
            role : role,
            acceptTerms: acceptTerms
        }

        fetch(URL, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify(data)
        }).then(response => {
            if(response.status===200) {
                alert("Registered successfully! You may now sign in.");
                redirectToSignIn(props)
                return response.json()
            } else return response.json()
        })
            .then(response => {
                if (response["message"] !== "") {
                    alert(response["message"])
                } else {
                    alert("An error has occurred. Please contact admin@upcycle4good.org for assistance.")
                }
                console.log('error')
            })
            .catch(error =>{
                console.log(error)
            })
    }

    function handleChange (e){
        const { name, value } = e.target;

        switch (name) {
            case 'firstName':
                if (!value || !minMaxLength(value, 2, 45)) {
                    setAlertMessage("First name should be at least 2 characters")
                    setAlertId('firstName')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    setFirstName(value)
                }
                break;
            case 'lastName':
                if (!value || !minMaxLength(value, 2, 45)) {
                    setAlertMessage("Last name should be at least 2 characters")
                    setAlertId('lastName')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    setLastName(value)
                }
                break;
            case 'email':
                if (!value || !validEmail(value) || !minMaxLength(value, 0, 50)) {
                    setAlertMessage("Email address is invalid")
                    setAlertId('email')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    setEmail(value)
                }
                break;
            case 'phoneNum':
                if (!minMaxLength(value, 0, 20)) {
                    setAlertMessage("Phone number cannot exceed 20 characters")
                    setAlertId('phoneNum')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    if (value !== "") {
                        setPhoneNum(value)
                    }
                }
                break;
            case 'organization':
                if (!value) {
                    setAlertMessage("Organization cannot be blank")
                    setAlertId('organization')
                } else if (!minMaxLength(value, 0, 50)) {
                    setAlertMessage("Organization cannot exceed 50 characters")
                    setAlertId('organization')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    setOrganization(value)
                }
                break;
            case 'website':
                if (!validWebsite(value)) {
                    setAlertMessage("Website is invalid (format: https://upcycle4good.org)")
                    setAlertId('website')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    if (value !== "") {
                        setWebsite(value)
                    }
                }
                break;
            case 'password':
                if (!minMaxLength(value, 6, 40)) {
                    setAlertMessage("Password should be at least 6 characters")
                    setAlertId('password')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    setPassword(value)
                }
                break;
            case 'confirmPassword':
                if (!minMaxLength(value, 6, 40)) {
                    setAlertMessage("Password should be at least 6 characters")
                    setAlertId('confirmPassword')
                } else if (value !== password?.toString()) {
                    setAlertMessage("Confirm password does not match password")
                    setAlertId('confirmPassword')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    setConfirmPassword(value)
                }
                break;
            default:
                break;
        }
    }

    function validRegistration() {

        // required values must be filled out
        if (firstName == null || lastName == null || email == null || role == null || organization == null
            || password == null || confirmPassword == null || !acceptTerms) {
            return false;
        }

        // all fields must be valid
        if (alertMessage) {
            return false;
        }

        return true;
    }

    return (
        <Container component="main" maxWidth="xs">
            {alertMessage &&
                <Alert severity="error">{alertMessage}</Alert>
            }
            <CssBaseline />
            <div className={classes.paper}>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // className={
                                //     alertId === "firstName" && classes.error
                                // }
                                error={alertId === "firstName"}
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                noValidate
                                onBlur={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={alertId === "lastName"}
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                                noValidate
                                onBlur={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={alertId === "email"}
                                autoComplete="email"
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                noValidate
                                onBlur={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={alertId === "phoneNum"}
                                variant="outlined"
                                fullWidth
                                id="phoneNum"
                                label="Phone Number"
                                name="phoneNum"
                                autoComplete="phoneNum"
                                noValidate
                                onBlur={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="account-type-label">Account Type</InputLabel>
                                <Select
                                    labelId="account-type-label"
                                    id="account-type"
                                    value={role}
                                    required
                                    label="Account Type"
                                    name="accountType"
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <MenuItem value={"donor"}>Donor</MenuItem>
                                    <MenuItem value={"charity"}>Charity</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={alertId === "organization"}
                                variant="outlined"
                                required
                                fullWidth
                                id="organization"
                                label="Organization"
                                name="organization"
                                autoComplete="organization"
                                noValidate
                                onBlur={handleChange}
                            />
                        </Grid>
                        {role === "donor"
                           && (<Grid item xs={12}>
                                <TextField
                                    error={alertId === "website"}
                                    variant="outlined"
                                    fullWidth
                                    id="website"
                                    label="Website"
                                    name="website"
                                    autoComplete="website"
                                    noValidate
                                    onBlur={handleChange}
                                />
                            </Grid>)
                        }
                        <Grid item xs={12}>
                            <TextField
                                error={alertId === "password"}
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                noValidate
                                onBlur={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={alertId === "confirmPassword"}
                                variant="outlined"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                noValidate
                                onBlur={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="acceptTerms" color="secondary" />}
                                label={termsLabel}
                                onChange={(e) => setAcceptTerms(e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!validRegistration()}
                        className={classes.submit}
                        onClick={ (e) => { handleSubmit(e, props)}}
                        style={{ marginBottom: 20, marginTop: 10 }}
                    >
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/auth/1" variant="body2" color={"primary"}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};