import React from 'react';
import { Container, Row, Col, } from 'reactstrap';
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';
import HomeSharpIcon from '@material-ui/icons/HomeSharp';
import FlightSharpIcon from '@material-ui/icons/FlightSharp';
import Typography from '@material-ui/core/Typography';

import CardImgOnY from '../Cards/cardImgOnY'
import styles from './visitorHome.module.css';
import PreservingEnv1 from '../../static/PreservingEnv1.jpg'
import PreservingEnv2 from '../../static/PreservingEnv2.jpg'
import PreservingEnv3 from '../../static/PreservingEnv3.jpg'

class VisitorHome extends React.Component {
    render() {
        return (
            <div>
                <Container fluid>
                    {/* <Row>
                        <Col>
                            <CarouselSlide
                                content={CAROUSEL_CONTENT} /></Col>
                    </Row> */}
                    <Row>
                        <Col xs="12" className={"px-0 " + styles.banner}>
                            <Typography variant="h1" component="p" className={styles.bannerText + " py-4 px-5"}>
                                Good for the <br /> Environment
                            </Typography>
                            <Typography variant="h6" component="p" align="left" className={styles.bannerCaption + " py-4 px-5"}>
                                Sustainably-minded, socially conscious partnerships work to reduce landfill waste and benefits communities.
                            </Typography>
                        </Col>
                    </Row>
                    <Row className={styles.whatWeDo + " mt-3"}>
                        <Col xs="12" className="text-center">
                            <Typography variant="h3" component="h3" className={styles.homeText}>What We Do</Typography>
                        </Col>
                    </Row>
                    <Row className={styles.whatWeDo}>
                        <Col md={{ size: "4", offset: "2" }} className=" mb-5 mr-3 py-5">
                            <Typography variant="h5" component="p" className={styles.para}>
                                Upcycle4Good is a 501(c)(3) that bridges
                                the gap between businesses and charities
                                that work with underserved communities.
                                We identify companies that have goods
                                that they no longer need and match them
                                with those who will put those goods back
                                into productive use.
                            </Typography>
                        </Col>
                        <Col md="4" className={styles.video + " mb-5 mx-2 py-2"}>
                        <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/jLBSYMoPo9Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="12" className="text-center">
                            <Typography variant="h3" component="h3" className={styles.homeText}>How We Can Do Good Together</Typography>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col xs={{ size: "8", offset: "2" }} className="text-center">
                            <Typography variant="h6" component="p" className={styles.para}>
                                Whether facilitating a transfer of goods from a donor to a recipient, or coordinating the sale of
                                donated goods and using the proceeds to facilitate logistics, Upcycle4Good's core objectives are achieved:
                                to divert goods away from landfills and put them back into use to help those in need.
                            </Typography>
                            <Typography variant="h6" component="p" className={styles.paraBold}>
                                Join our mission to preserve the environment and improve lives.
                            </Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" style={{ backgroundColor: "#61c2ee" }} className={styles.borderRight + " py-5 px-5"}>
                            <Typography variant="h4" component="h3" className="text-center px-5">
                                <LanguageSharpIcon fontSize="large" />&nbsp; DONATE Surplus Goods
                            </Typography>
                            <Typography variant="h6" component="p" className={"px-5 mt-4"}>
                                If you have large-scale commercial surplus goods with years or remaining useful life, consider donating them.
                            </Typography>
                            <Typography variant="h6" component="p" className={"px-5 mt-4"}>
                                Upcycle4Good facilitates the transfer of these surplus goods to registered charities and underserved communities
                                both domestically and internationnaly.
                            </Typography>
                            <Typography variant="h6" component="p" className={"px-5 mt-4"}>
                                We support corporate outward-facing sustainability initiatives efforts by identifying recipients of donations and
                                providing a business case for the donation.
                            </Typography>
                            <Typography variant="h6" component="p" className={"px-5 mt-4 "} style={{ fontWeight: "bold" }}>
                                &gt; Register to learn more
                            </Typography>
                        </Col>
                        <Col md="6" style={{ backgroundColor: "#4d555b", color: "#fff" }} className={styles.borderLeft + " py-5 px-5"}>
                            <Typography variant="h4" component="h3" className="text-center px-5">
                                <HomeSharpIcon fontSize="large" />&nbsp; REGISTER Your Charity
                            </Typography>
                            <Typography variant="h6" component="p" className={"px-5 mt-4"}>
                                Upcycle4Good is here, in part, to support your mission. Many businesses have items that are no longer useful to them,
                                but can be of real value to your Charity's mission.
                            </Typography>
                            <Typography variant="h6" component="p" className={"px-5 mt-4"}>
                                Our goal is to connect you both and expedite a mutually beneficial transfer of these items
                            </Typography>
                            <Typography variant="h6" component="p" className={"px-5 mt-5 "} style={{ fontWeight: "bold" }}>
                                &gt; Register to find goods
                            </Typography>
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: "rgb(230, 230, 230)" }} className="mt-3 py-4">
                        <Col xs="1" className="text-right" style={{ transform: "rotate(45deg)" }}><FlightSharpIcon style={{ fontSize: "80px" }} /></Col>
                        <Col xs="11" className="text-center">
                            <Typography variant="h4" component="h4" >Become a Logistics Partner</Typography>
                            <Typography variant="h6" component="p" className={"px-5 mt-4"}>
                                Do you represent a logistics company willing to utilize spare capacity to benefit others?
                            </Typography>
                            <Typography variant="h6" component="p">
                                We need partners to make good happen! <span style={{ fontWeight: "bold" }}>Learn More.</span>
                            </Typography>
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: "rgb(236, 233, 187)" }} className="mt-3 py-4">
                        <Col xs="12" className="text-center">
                            <Typography variant="h4" component="h3" style={{ color: "rgb(7, 77, 130)" }}>Preserving the Environment.</Typography>
                            <Typography variant="h4" component="h3" style={{ color: "rgb(7, 77, 130)" }}>Improving Lives.</Typography>
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: "rgb(236, 233, 187)" }}>
                        <Col xs={{ size: "8", offset: "2" }} md={{ size: "6", offset: "3" }} className="text-center">
                            <Typography variant="h6" component="p">Upcycle4Good facilitates business-to-business connections that reduce
                            waste while allowing charities to put these goods back to use. Here are some examples.</Typography>
                        </Col>
                    </Row>
                    <Row style={{ backgroundColor: "rgb(236, 233, 187)" }} className="py-5">
                        <Col md="4" className="px-5">
                            <CardImgOnY
                                img={PreservingEnv1}
                                title={"PreservingEnv1"}
                                text={
                                    <div>
                                        <Typography variant="h5" component="body">
                                            <span style={{ fontWeight: "bolder" }}>Problem:</span>
                                            For businesses in the hospitality industry, furniture, appliances, and other items may
                                            become scratched or dented, rendering them "useless" for the business, while retaining many years
                                            of useful life.
                                        </Typography>
                                        <Typography variant="h5" component="body" className="mt-3">
                                            <span style={{ fontWeight: "bolder" }}>Solution:</span>
                                        Hospital patient rooms or school science labs in underserved communities may put items such
                                        as small refrigerators, previously used as hotel mini-bars, to good use.
                                    </Typography>
                                    </div>
                                } />
                        </Col>
                        <Col md="4" className="px-5">
                            <CardImgOnY
                                img={PreservingEnv2}
                                title={"PreservingEnv2"}
                                text={
                                    <div>
                                        <Typography variant="h5" component="body">
                                            <span style={{ fontWeight: "bolder" }}>Problem:</span>
                                            Construction contractors purchase bulk quantities of tools, materials and equipment for
                                            multiple-year projects. At completion these items are sent to an equipment yard and stored for
                                            extended periods, at which time they are trashed or sold for nominal amounts.
                                        </Typography>
                                        <Typography variant="h5" component="body" className="mt-3">
                                            <span style={{ fontWeight: "bolder" }}>Solution:</span>
                                            Underserved communities are frequently in search of construction tools and
                                            equipment to support efforts to provide housing or access to water.
                                        </Typography>
                                    </div>
                                } />
                        </Col><Col md="4" className="px-5">
                            <CardImgOnY
                                img={PreservingEnv3}
                                title={"PreservingEnv3"}
                                text={
                                    <div>
                                        <Typography variant="h5" component="body">
                                            <span style={{ fontWeight: "bolder" }}>Problem:</span>
                                            Mattress stores promote their brands by offering no-cost trial periods. Returned
                                            mattresses cannot be resold as new, but rather may be destined for landfills.
                                        </Typography>
                                        <Typography variant="h5" component="body" className="mt-3">
                                            <span style={{ fontWeight: "bolder" }}>Solution:</span>
                                            Community shelters where mattresses are used for periods of time longer than they are intended,
                                            and hospitals in Ethiopia, where many patients are wrapped in blankets and rest on the floor, are
                                            places in dire need of mattresses.
                                        </Typography>
                                    </div>
                                } />
                        </Col>

                    </Row>
                </Container>
            </div>
        )
    };
}

export default VisitorHome;