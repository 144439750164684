import React, { useContext, useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useMutation } from 'react-query';
import Axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { getAccessToken, userIsAdmin } from '../../helpers/authHelpers';
import UserContext from '../../contexts/UserContext';
import './productCard.css';
import StatusChip from './statusChip';

function ProductCard(props) {
    const [user] = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [alertOpen, setAlertOpen] = useState(null);
    const [alertMessage, setAlertMessage] = useState('Error');
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const mutation = useMutation((() => {
        const accessToken = getAccessToken();

        return Axios.delete(`${process.env.REACT_APP_API_URL}/products/${props.id}`, 
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }), {
        onSettled: () => {
            // refetch();
            handleClose();
            props.refetch();
        },
        onError: (e) => {
            setAlertOpen(true);
            console.error(e);
        },
    });

    const deleteProduct = () => {
        mutation.mutate();
    };

    return (
        <Card className="product-card" variant="outlined">
            <div className='status-chip'>
                <StatusChip productStatus={props.statusCode} />
            </div>
            <CardMedia
                className='media'
                image={props.imgURL}
                title={props.name}
            />
            <CardContent className="card-content">
                <Typography variant="h6" className="product-name" gutterBottom>
                    {props.name}
                    {(props.donorUserId === user?.id || userIsAdmin(user)) && (
                        <span className="actions-button">
                            <IconButton onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={deleteProduct}>
                                    <ListItemIcon>
                                        {mutation.isLoading
                                            ? <CircularProgress size={16} color="inherit" />
                                            : <DeleteIcon />
                                        }
                                    </ListItemIcon>
                                    <ListItemText>
                                        Delete
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </span>
                    )}
                </Typography>
                <Typography
                    variant="caption"
                    gutterBottom
                    style={{ marginBottom: 5, display: 'block' }}
                >
                    <DateRangeIcon
                        style={{ width: 17, height: 17, marginRight: 3, marginTop: -3 }}
                    />
                        Posted: {moment(props.publishedOn).format('MMMM Do')}
                </Typography>
                {props.pickupDeadline && (
                    <Typography
                        variant="caption"
                        gutterBottom
                        style={{ marginBottom: 5, display: 'block' }}
                    >
                        <DateRangeIcon
                            style={{ width: 17, height: 17, marginRight: 3, marginTop: -3 }}
                        />
                            Deadline: {moment(props.pickupDeadline).format('MMMM Do')}
                    </Typography>
                )}
                <Typography variant="body2">
                    {props.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Link
                    to={`/products/${props.id}`}
                    style={{ width: '100%', textDecoration: 'none' }}
                >
                    <Button
                        fullWidth
                        variant="outlined"
                        style={{ display: 'block', width: '100%' }}
                    >
                        More Details
                    </Button>
                </Link>
            </CardActions>
            <Snackbar open={alertOpen} autoHideDuration={5000} onClose={() => setAlertOpen(false)}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    This product cannot be deleted unless its existing claim is canceled.
                </Alert>
            </Snackbar>
        </Card>
    );
}

export default ProductCard;
