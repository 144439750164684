import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDNuhWsMZcdl-aZDTkw52R4zCujwpX3RVY",
  authDomain: "upcycle4good-f58a3.firebaseapp.com",
  projectId: "upcycle4good-f58a3",
  storageBucket: "upcycle4good-f58a3.appspot.com",
  messagingSenderId: "308814929637",
  appId: "1:308814929637:web:a82ff4329a432f4c8d3e0c"
};

const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);

export default firebaseApp;