import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import firebaseApp from "../config/firebase";

const storage = getStorage(firebaseApp);

/**
 * Helper function to upload images to firebase.
 * 
 * @param file file reference
 * @returns image download URL
 */
export const uploadFile = async (file) => {
    if(!file) return;

    const storageRef = ref(storage, 'product-images/' + Date.now());

    const snapshot = await uploadBytes(storageRef, file);

    const downloadUrl = await getDownloadURL(snapshot.ref);

    return downloadUrl;
}
