import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


function CardImgOnY(props) {
    return (
        <Card style={{height:"100%"}}>
            <CardMedia
                square
                component="img"
                image={props.img}
                title={props.title ? props.title : ""}
                height="250"/>
            <CardContent>
                {props.text}
            </CardContent>
        </Card >
    );
}

export default CardImgOnY;