import React from "react";
import { Container, Row, Col } from "reactstrap";
import styles from "./faq.module.css";
import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

class FAQ extends React.Component {
  render() {
    return (
      <div>
        <Container fluid>
          <Row>
            <Col lg="6" className="px-5" style={{ marginTop: "5%" }}>
              <Row>
                <Col>
                  <h1 className={styles.formMainText}>FAQ's</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Accordion className={styles.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <HelpOutlineIcon className={styles.accordionIcons} />

                      <Typography className={styles.heading}>
                        Is Upcycle4Good Foundation a registered charity?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordingDetail}>
                      <Typography>
                        Yes.We are approved charity udner section 501(c)(3) of
                        the U.S. Internal Revenue Code, recognized as a
                        charitable organization.Use our contact us page if you
                        would like a copy of the documentation. Donations made
                        to Upcycle4Good may be tax deductible. (Please check
                        with your tax advisor to confirm your own tax
                        situation.)
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={styles.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <HelpOutlineIcon className={styles.accordionIcons} />
                      <Typography className={styles.heading}>
                        Do Donors donate items to Upcycle4Good,for transfer to
                        charities?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordingDetail}>
                      <Typography>
                        Typically not.Upcycle4Good facilicates the donation of
                        items from businesses directly to charities and
                        developing communities.Donors of items may therefore
                        secure receipts of their donations from the charities
                        that recieve them.The Upcycle4Good team will be happy to
                        help secure these receipts, if necessary. If a Donor's
                        item cannot be matched with a recieving charity, and the
                        item is sold -- with the proceeds going to Upcycle4Good
                        -- then the item is considered to have been donated to
                        Upcycle4Good, with the sales price being the value of
                        the donation.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={styles.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <HelpOutlineIcon className={styles.accordionIcons} />
                      <Typography className={styles.heading}>
                        Does Upcycle4Good accept donations directly?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordingDetail}>
                      <Typography>
                        Yes.We accept monetary donations and of in-kind service
                        that further our mission. We are always in search of
                        transportation logistics partners that are able to
                        facilitate donations to charities and developing
                        communities.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={styles.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <HelpOutlineIcon className={styles.accordionIcons} />
                      <Typography className={styles.heading}>
                        How much of any monetary donation is used to cover the
                        costs of staff?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordingDetail}>
                      <Typography>
                        None.Upcycle4Good is a volunteer run organization.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={styles.faqAccordion}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <HelpOutlineIcon className={styles.accordionIcons} />
                      <Typography className={styles.heading}>
                        Does Upcycle4Good share any of the contact information
                        that it collects on the site?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordingDetail}>
                      <Typography>
                        Upcycle4Good does not share any of the information that
                        is submitted on the website.We will make every effort to
                        protect the information submitted and stored on our
                        site.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion disabled className={styles.faqAccordion}>
                    <AccordionSummary

                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className={styles.heading}>
                        If you have questions about Upcycle4Good Foundation, our
                        mission or the way we work,please don't hesitate to
                        reach out! Use our contact us feature , or{" "}
                        <b>Upcycle4Good@gmail.com</b> or call us at
                        (516)993-9360.
                      </Typography>
                    </AccordionSummary>
                  </Accordion>
                </Col>
              </Row>
            </Col>
            <Col xs="12" lg="6" className={"px-2 " + styles.banner}>
              <Typography
                variant="h5"
                component="h2"
                align="justify"
                className={styles.bannerText + " py-3"}
              >
                <b> Hi! </b> <br />
                We're here to answer your questions
                <br />
                (however you want to ask them)
              </Typography>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FAQ;
