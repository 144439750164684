import React from "react";
import {Container, Row, Col} from "reactstrap";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import styles from "./howTo.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
});

export default function HowTo(props) {
    const classes = useStyles();

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col xs="12" className={"px-0 " + styles.banner}>
                        <Typography variant="h5" component="h2" align="justify" className={styles.bannerText + " py-3"}>
                            Learn how to utilize Upcycle4Good's <br/>
                            tools and resources.
                        </Typography>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-5">

                <Row>
                    {/* Donors */}
                    <Col md="6" className="text-center mt-2">
                        <h1 className={"text-center"}>Donors</h1>
                        <Col md={12}>

                            <Card variant="outlined" style={{ "backgroundColor": "#6cb545" }}>
                                <CardContent>
                                    <Typography className={classes.pos} color="white">
                                        Learn how to become recognized as a Donor and begin listing your products.
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Accordion>
                                <AccordionSummary>
                                    <Typography><strong>Registration</strong></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography align={'left'}>
                                        In order to post products for donation, you must first register an account.
                                        The registration form will ask for two important details:<br/><br/>

                                        1) <strong>Contact Information:</strong> in case the U4G administrator has
                                        questions regarding your donation; to coordinate logistics; and to share with a
                                        charity when they claim your product.<br/>
                                        2) <strong>Organization Details:</strong> used in our verification
                                        process.<br/><br/>

                                        After registration, your account will be placed in a "pending" mode until an
                                        admin reviews your application. During this time, you will be able to sign in
                                        and edit your profile, but you will not be able to post products.<br/><br/>

                                        If more information is needed to approve your application, an admin may
                                        contact you using your email address or phone number.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary>
                                    <Typography><strong>Posting a Product</strong></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography align={'left'}>
                                        Once your account registration is completed, you will be able to list products
                                        for donation. Your organization and contact information is <strong>not</strong> attached
                                        to a listed product. U4G uses email to share your information with a
                                        single Charity when they claim your item. If you prefer to list a product
                                        anonymously, please contact the U4G administrator.  In this case, your contact
                                        information would not be automatically shared with a Charity.<br/><br/>

                                        To create a new listing, sign in to your account and navigate to the <strong>Products
                                        page</strong>. Click the <strong>circular plus icon</strong> in the bottom right
                                        corner. Enter your product
                                        details and click <strong>Publish</strong>.<br/><br/>

                                        <Accordion>
                                            <AccordionSummary style={{ "backgroundColor": "#b9d76b" }}>
                                                <Typography align={'left'}>Click here for more information on product
                                                    details.</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography align={'left'}>
                                                    <strong>Image:</strong> Upload a picture of your donation. If
                                                    multiple images are needed, use editing software to combine your
                                                    images into one.<br/><br/>
                                                    <strong>Name:</strong> Please keep this as concise as possible.
                                                    Quantity, brand, and other details are listed elsewhere.<br/><br/>
                                                    <strong>Market Value:</strong> Estimate the value of your entire
                                                    donation. If your product is not claimed, U4G may ask for permission
                                                    to list your items for sale. (Sale proceeds are used exclusively to
                                                    facilitate transportation logistics of other donations to
                                                    charities.)<br/><br/>
                                                    <strong>Quantity:</strong> The total number of similar items being
                                                    offered.<br/><br/>
                                                    <strong>Description:</strong> Include any extra important details
                                                    not described elsewhere.<br/><br/>
                                                    <strong>Category:</strong> Used to filter through listed products.
                                                    If you would like a new category created, please contact
                                                    admin@upcycle4good.org or use the "Contact Us" feature.<br/><br/>
                                                    <strong>Location:</strong> This is shared publicly, so you do not
                                                    need to be precise. It is referenced to determine if
                                                    transportation/pickup is possible.<br/><br/>
                                                    <strong>Shipping Available:</strong> Check this box if you are
                                                    willing to organize and pay for the transportation/delivery of your
                                                    donation. If you have limitations, please provide them in the
                                                    Description.<br/><br/>
                                                    <strong>Pickup Deadline:</strong> If you cannot hold onto an item
                                                    indefinitely, check this box and indicate when the product must be
                                                    shipped/picked up by.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <br/>
                                        Once you publish a product, it cannot be edited. If you make a mistake or need
                                        to change the details, please delete the product and start anew. The delete
                                        button can be accessed by clicking the "three dot menu" in the right
                                        corner of the product listing on the Products page.<br/><br/>

                                        U4G holds the right to delete your product listing if necessary.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary>
                                    <Typography><strong>Organizing Transportation</strong></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography align={'left'}>
                                        When one of your listed products is claimed, you and the Charity that claimed
                                        the product will each receive an email with each other's contact information. We
                                        use an automated email service, so <strong>please check your
                                        spam</strong> frequently.
                                        <br/><br/>

                                        Reach out to the contact provided to begin arranging transportation of
                                        the donation. If assistance is needed, please reply to the original email
                                        from admin@upcycle4good.org or use our Contact Us page.<br/><br/>

                                        If the exchange will not be possible, <strong>either party can cancel the claim
                                        of the product at any time</strong>, and the product will be made
                                        available once again.<br/><br/>

                                        <strong>Disclaimer:</strong> U4G only facilitates the connection between Donor
                                        and Charity. We do not claim responsibility for the successful exchange of
                                        donations.<br/><br/>

                                        If you know of any logistics companies that may be interested and willing to
                                        participate in our mission, please ask them to reach out to us. Or, provide us
                                        with their contact information. We are always looking for assistance with the
                                        transportation of donated products. If a logistics company has available space
                                        and is willing to make it available for the transportation of donated items, we
                                        are once again helping the environment by making efficient use of transportation
                                        options, while supporting charities. Thank you!
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary>
                                    <Typography><strong>Product Sales</strong></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography align={'left'}>
                                        If you set a deadline by which time your donation must be claimed, and that
                                        deadline is approaching, a U4G admin may contact you to ask for permission to
                                        attempt the sale of your items. Please consider allowing us to offer them for
                                        sale through U4G.<br/><br/>

                                        During the listing process for a product, you are asked to estimate the value
                                        of the donation. This estimate will be used when selling the items.<br/><br/>

                                        Our site does not currently support the purchase of goods. A U4G admin would
                                        attempt to sell the items independently of the U4G website.<br/><br/>

                                        You are not obligated to offer goods for sale, assuming no charity claims them.
                                        We offer them for sale for two reasons:<br/><br/>

                                        1) Assuming we are unable to identify a Charity within a Donor's timeline, we
                                        still want to avoid having useful items go to waste.<br/>
                                        2) All proceeds fund the transportation costs for other donations. In short,
                                        the funds will be put to good use. (Proceeds from the sale of donated items
                                        are <strong>not</strong> used to cover U4G administrative expenses.)<br/><br/>

                                        Thank you for joining us in our mission to reduce the growth of landfills, while
                                        helping others!
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>


                    {/*        <Col md="12" className="text-center mt-5 ">*/}
                    {/*            <img src={Services_2} alt="Services_2" style={{width: "100%", height: "auto"}}*/}
                    {/*                 className="img-fluid"/>*/}
                    {/*        </Col>*/}
                        </Col>
                    </Col>


                    {/* Charities */}
                    <Col md="6" className="text-center">
                        <h1 className={"text-center"}>Charities</h1>
                        <Col md={12}>

                            <Card variant="outlined" className="mt-3" style={{ "backgroundColor": "#074d82" }}>
                                <CardContent>
                                    <Typography className={classes.pos} color="white">
                                        Learn how to become recognized as a U4G Charity and claim available
                                        donations.
                                    </Typography>
                                </CardContent>
                            </Card>

                            <Accordion>
                                <AccordionSummary>
                                    <Typography><strong>Registration</strong></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography align={'left'}>
                                        If you are a recognized charity as defined by the IRS Code, or represent
                                        a developing community, register for access to donated products. The
                                        registration form will ask for two important details:<br/><br/>

                                        1) <strong>Contact Information:</strong> in case the U4G administrator has
                                        questions regarding your organization; to coordinate logistics; and to share
                                        with a Donor when you claim their product.<br/>
                                        2) <strong>Organization Name:</strong> used in our verification
                                        process.<br/><br/>

                                        After registration, you will receive a welcome email. Please respond to this
                                        email and attach your 501(c)(3) verification so we may begin your application
                                        review. We use an automated email service, so <strong>please check your spam</strong>.
                                        <br/><br/>

                                        Until we approve your registration, your account will be placed in a "pending"
                                        mode. During this time, you will be able to sign in, edit your profile and
                                        view donated items, but you will not be able to claim products.<br/><br/>

                                        If more information is needed to approve your registration, an admin may
                                        contact you using your provided email or phone number.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary>
                                    <Typography><strong>Claiming a Product</strong></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography align={'left'}>
                                        Once your account is approved, you will be able to claim products for your
                                        organization. U4G uses email to share your contact information with a
                                        single Donor when you claim their item. <br/><br/>

                                        To claim a listing, sign in to your account and navigate to
                                        the <strong>Products</strong> page. Click <strong>More Details</strong> for any
                                        product, review the product details to determine if the donation is right for
                                        you, and then click <strong>Claim</strong>. <br/><br/>

                                        If the Claim button is not there, you are either not signed in or your account
                                        has not been approved. If the button is grayed out, then the product is already
                                        claimed.<br/><br/>

                                        <Accordion>
                                            <AccordionSummary style={{ "backgroundColor": "#61c2ee" }}>
                                                <Typography>Click here for more information on product
                                                    details.</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography align={'left'}>
                                                    <strong>Image:</strong> Each product listing can currently only
                                                    support one image.<br/><br/>
                                                    <strong>Name:</strong> A minimal description of the
                                                    product.<br/><br/>
                                                    <strong>Market Value:</strong> The estimated value of the entire
                                                    donation. If the product is not claimed, this may be used to list
                                                    it for sale.<br/><br/>
                                                    <strong>Quantity:</strong> The total number of similar items being
                                                    offered.<br/><br/>
                                                    <strong>Description:</strong> Includes any extra important details
                                                    not described elsewhere.<br/><br/>
                                                    <strong>Category:</strong> Used to filter through listed products.
                                                    If you would like a new category created, please contact
                                                    admin@upcycle4good.org.<br/><br/>
                                                    <strong>Location:</strong> This is shared publicly, so it will
                                                    likely not be precise. Use it to determine if transportation/pickup
                                                    is possible.<br/><br/>
                                                    <strong>Shipping Available:</strong> This indicates if the Donor is
                                                    willing to organize and pay for the transportation/delivery of this
                                                    donation. If they have limitations, it should be provided in the
                                                    product description.<br/><br/>
                                                    <strong>Pickup Deadline:</strong> If the Donor cannot hold onto an
                                                    item indefinitely, this indicates when the product must be
                                                    shipped/picked up by.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <br/>
                                        Once you claim a product, it will become unavailable for other Charities. Please
                                        check your email (and your spam) for the Donor's contact details and reach out
                                        to them as soon as possible.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary>
                                    <Typography><strong>Organizing Transportation</strong></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography align={'left'}>
                                        When you claim a product, you and the Donor who listed the product will each
                                        receive an email with each other's contact information. We use an automated
                                        email service, so <strong>please check your spam</strong>.<br/><br/>

                                        Reach out immediately and arrange for the transfer of the items. Many Donors have time
                                        constraints and many not be able to store an item past a certain date.<br/><br/>

                                        While many Donors are happy to offer the items, they may be unable to cover
                                        the cost of transportation. Be sure to discuss transportation logistics
                                        and check your budget for funds.<br/><br/>

                                        If help with transportation is required, please contact U4G. We are happy to
                                        assist, within our budget.<br/><br/>

                                        If the exchange will not be possible, <strong>either party can cancel the claim
                                        of the product at any time</strong>, and the product will be made
                                        available once again.<br/><br/>

                                        <strong>Disclaimer:</strong> U4G only facilitates the connection between Donor
                                        and Charity. We do not claim responsibility for the successful exchange of
                                        donations.<br/><br/>

                                        If you know of any logistics companies that may be interested and willing to
                                        participate in our mission, please ask them to reach out to us. Or, provide us
                                        with their contact information. We are always looking for assistance with the
                                        transportation of donated products. If a logistics company has available space
                                        and is willing to make it available for the transportation of donated items, we
                                        are once again helping the environment by making efficient use of transportation
                                        options, while supporting charities. Thank you!
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                        </Col>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}
