import React from 'react';
import Chip from '@mui/material/Chip';

import { PRODUCT_STATUS } from '../../helpers/productHelpers';

const StatusChip = ({productStatus}) => {
    return (
        <Chip
            label={PRODUCT_STATUS[productStatus]?.label}
            variant="outlined"
            size="small"
            style={{
                marginRight: 10,
                color: productStatus === PRODUCT_STATUS.AVAILABLE.key ? 'green'
                    : productStatus === PRODUCT_STATUS.CLAIMED.key ? '#ff7200'
                    : 'red',
                border: '1px solid',
            }}
        />
    );
};

export default StatusChip;