import React, {useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@mui/material/Container';
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        width: "20%",
        height: "auto",
        display: "inline"
    },
}));


function redirectToSign(props){

    props.history.push('/auth/1')

}

export default function ResetPassword(props) {

    const [email, setEmail] = useState('');
    const [successOpen, setSuccessOpen] = useState(false);
    const classes = useStyles();

    function handleSubmit(event){

        event.preventDefault();
        const URL = `${process.env.REACT_APP_API_URL}/reset-password/${email}`

        fetch(URL, {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                "Access-Control-Allow-Origin": "*"
            },
        }).then(response => {
            if(response.status===200)
                return response.json()
            else return null
        })
            .then(parsedResponse => {
                if(parsedResponse!=null){
                    setSuccessOpen(true)
                }else{
                    alert('password-reset-failed')
                    console.log('error')
                }
            })
            .catch(error =>{
                console.log(error)
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            {successOpen
                ? <Alert severity="success">If this is an active account, then the password has been reset. Please
                    check your email (or spam) for further instructions.</Alert>
                : <Alert severity="info">Please enter the email associated with your account below.</Alert>
            }
            <CssBaseline />
            <div className={classes.paper}>

                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        type="email"
                        label="Email Address"
                        name="email"
                        value={email}
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        style={{ marginBottom: 20, marginTop: 20 }}
                        onClick={ (e) => { handleSubmit(e)}}
                    >
                        Reset Password
                    </Button>
                    <Grid container>
                        <Grid item>
                            <Link href="/auth/1" variant="body2" id="creds-panel-tab-1">
                                {"Return to sign in"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
};