import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import Axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

import { getAccessToken } from '../../../helpers/authHelpers';
import StatusChip from '../../Products/statusChip';

function Activity() {
    const accessToken = getAccessToken();

    const {
        isLoading,
        data
    } = useQuery('productLogs', async () => {
        const response =  await Axios.get(`${process.env.REACT_APP_API_URL}/products/logs?size=9999`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        return response?.data;
    }, {
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        console.log(data)
    }, [data]);
            
    return (
        <Container>
                <Typography variant="h3" className="mb-3" gutterBottom>
                    Activity
                    {isLoading && (
                        <>
                            {' '}
                            <CircularProgress size={30} />
                        </>
                    )}
                </Typography>
                <Divider sx={{
                    backgroundImage: "linear-gradient(to right, #61c2ee, #b9d76b)",
                    borderBottomWidth: 3,
                    borderRadius: 1,
                    marginBottom: 0
                }}/>

            {!isLoading && data?.productLogs?.length > 0 && (
                data.productLogs.map((log, index) => (
                    <div key={index} style={{ paddingTop: 15 }}>
                        <Typography variant="caption" gutterBottom component="div">
                            <StatusChip productStatus={log.status} />
                            {moment(log?.createdAt).format('MMMM Do, YYYY h:mma')}
                        </Typography>
      
                        <Typography variant="h6" gutterBottom>
                            <Link to={`/products/${log.product.id}`}>{log.product.name}</Link>
                            
                        </Typography>
                        
                        <Typography variant="body2" gutterBottom component="div">
                            <div>{log.user.firstName} {log.user.lastName}</div>
                            <div>{log.user.email}</div>
                        </Typography>
                        {log.product.pickupDeadline && (
                            <Typography variant="caption" gutterBottom component="div">
                                Pickup deadline: {moment(log.product.pickupDeadline).format('MMMM Do, YYYY h:mma')}
                            </Typography>
                        )}
                        <Divider style={{ marginTop: 15 }} />
                    </div>
                ))
            )}
        </Container>
    );
};

export default Activity;
