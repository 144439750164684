import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

import authHandler from '../Auth/authHandler';
import './greet.css';
import { APP_LOGOUT } from '../../helpers/authHelpers';
import Avatar from '@mui/material/Avatar';
import UserContext from '../../contexts/UserContext';

function Greet() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [user, setUser] = useContext(UserContext);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // TODO: add on click handling of different MenuItems
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{display:'inline'}}>
            <div className='button-container' onClick={handleClick}>
                <Avatar className='user-avatar' alt={user?.firstName} />
                <Button aria-controls="user-dropdown" aria-haspopup="true" style={{color:'black', size: "18px",fontWeight:"bold"}}>
                    {user?.firstName}
                </Button>
            </div>
            <Menu
                className='user-menu-dropdown'
                id="user-dropdown"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem component={Link} to={'/dashboard/profile'} onClick={handleClose} >Dashboard</MenuItem>
                <MenuItem onClick={() => { APP_LOGOUT(); handleClose()}}>Logout</MenuItem>
            </Menu>
        </div>
    )
}

export default Greet;
