import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Typography } from "@material-ui/core";

import About from '../../static/PreservingEnv2.jpg'
import CoreBeliefs from '../../static/BannerHome.jpg'
import Strategy from '../../static/services.png'
import styles from "./aboutUs.module.css";
import CardImgOnX from '../Cards/cardImgOnX';


const ABOUT_US_CARDS = [
  {
    title: "About Us",
    subtitle: "Preserve the environment. Improve lives.",
    text: `Upcycle4Good is a sustainability-minded, socially
    conscious nonprofit. We make partnerships between
    sustainable businesses having items with residual useful
    life and charities and developing communities that put
    those items to good use. We identify opportunities for
    no-cost, win-win sustainable transactions.`,
    img: About
  },
  {
    title: "Strategy",
    subtitle: "Identifying opportunities for no-cost, win-win sustainable transaction.",
    text: `Divert goods perceived by businesses to be at end-of-life
    away from landfills and towards charities and underserved
    communities able to use those goods to enhance the quality
    of life of those in need. Offer attractively-priced sale
    as a secondary alternative, with proceeds used to fund
    transportation logistics.`,
    img: Strategy
  },
  {
    title: "Core Beliefs",
    subtitle: "Encourage change by offering alternatives, facilitating partnerships.",
    text: `We believe in helping others and our planet. We promote
           environmental awareness while improving lives. We make it
           easy for companies to take environmentally-friendly,
           socially-conscious actions. Together we can do the right
           thing.`,
    img: CoreBeliefs
  },
]


class AboutUs extends React.Component {
  render() {
    var imgLeft = true;

    const cards = ABOUT_US_CARDS.map((card) => {
      imgLeft = !imgLeft;
      return (
        <Row>
          <Col xs={{ size: "10", offset: "1" }} className="mt-3">
            <CardImgOnX
              title={card.title}
              subtitle={card.subtitle}
              description={card.text}
              img={card.img}
              imgLeft={imgLeft} />
          </Col>
        </Row>
      )
    })
    return (
      <div className="mb-3">
        <Container fluid>
          <Row>
            <Col xs="12" className={"px-0 " + styles.banner}>
              <Typography variant="h5" component="h2" align="justify" className={styles.bannerText + " py-3"}>
                Preserve the environment.<br />Identifying Opportunities.<br />Encourage change by offering alternatives
                </Typography>
            </Col>
          </Row>
        </Container>
        <Container>
          {cards}
        </Container>
      </div>
    );
  }
}

export default AboutUs;
