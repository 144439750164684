import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container} from "reactstrap";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import { ProtectedRoute } from './components/Auth/protectedRoute';
import Products from './components/Products/products'
import AboutUs from './components/AboutUs/aboutUs';
import ContactUs from './components/ContactUs/contactUs';
import Auth from './components/Auth/auth'
import VisitorHome from './components/VisitorHome/visitorHome'
import HowTo from './components/HowTo/howTo'
import Footer from './components/Footer/footer'
import Dashboard from './components/Dashboard/dashboard';
import FAQ from './components/Faq/faq'
import ProductDetails from './components/Products/productDetails';
import NavBar from './components/NavBar/navBar';
import Profile from './components/Dashboard/Profile/profile';
import ManageUsers from './components/Dashboard/ManageUsers/ManageUsers';
import ManageCategories from './components/Dashboard/ManageCategories/manageCategories';
import UserContext from './contexts/UserContext';

import { getAccessToken, userIsPending } from './helpers/authHelpers';
import Activity from './components/Dashboard/Activity/activity';

const queryClient = new QueryClient();

const theme = createTheme({
    typography: {
        "fontFamily": `"Poppins", "Helvetica", "Arial", sans-serif`,
    },
    palette: {
        primary: {
            light: "#61c2ee",
            main: "#074d82"
        },
        secondary: {
            light: "#b9d76b",
            main: "#6cb545"
        }
    }
});

function App() {
    const [user, setUser] = useState(null);
    const [pendingMessageOpen, setPendingMessageOpen] = useState(false);

    useEffect(() => {
        const accessToken = getAccessToken();
        if (accessToken) {
            const decodedToken = JSON.parse( Buffer.from((accessToken.split(".")[1]), 'base64') );

            // decodedToken.exp contains a Unix timestamp of the expiration
            Axios.get(`${process.env.REACT_APP_API_URL}/users/${decodedToken.sub}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).then((response) => {
                setUser(response.data);
            });
        }
    }, []);

    useEffect(() => {
        if (user && userIsPending(user)) {
            setPendingMessageOpen(true);
        }
    }, [user]);

    return (
        <QueryClientProvider client={queryClient}>
            <UserContext.Provider value={[user, setUser]}>
                <ThemeProvider theme={theme}>
                    <div className='main-content'>
                        <Router>
                            <NavBar />
                            <Routes>
                                <Route exact path='/' element={<Products />} />
                                <Route path='auth'>
                                    <Route path='/auth' element={<Auth />} />
                                    <Route path=':authPage' element={<Auth />} />
                                </Route>
                                <Route path='/aboutus' element={<AboutUs />} />
                                <Route path='/how-to' element={<HowTo />} />
                                <Route path='products'>
                                    <Route path=':productId' element={<ProductDetails />} />
                                </Route>
                                <Route path='/dashboard' element={<ProtectedRoute />}>
                                    <Route path='/dashboard' element={<Dashboard />}>
                                        <Route path="/dashboard/activity" element={<Activity />} />
                                        <Route path="/dashboard/users" element={<ManageUsers />} />
                                        <Route path="/dashboard/profile" element={<Profile />} />
                                        <Route path="/dashboard/categories" element={<ManageCategories />} />
                                    </Route>
                                </Route>
                                <Route path='/contactus' element={<ContactUs />} />
                                <Route path='/faq' element={<FAQ />} />
                            </Routes>
                        </Router>
                    </div>
                    <Container fluid>
                        <Footer/>
                    </Container>
                    <Snackbar open={pendingMessageOpen}>
                        <Alert severity='info'>
                            Your account is currently pending approval
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5, marginLeft: 5 }}
                                onClick={() => setPendingMessageOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Alert>
                    </Snackbar>
                </ThemeProvider>
            </UserContext.Provider>
        </QueryClientProvider>
    );
}

export default App;
