import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import UserRow from './UserRow';

function UserTable({ users, refetch, type }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                <TableRow>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Organization</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
                </TableHead>
                {users && users?.users?.length > 0 && (
                    <TableBody>
                        {users.users.map((user) => (
                            <UserRow user={user} refetch={refetch} key={user.userId} type={type} />
                        ))}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

export default UserTable;
