export const PRODUCT_STATUS = {
    "AVAILABLE": {
        id: 1,
        key: "AVAILABLE",
        label: "Available"
    },
    "CLAIMED": { 
        id: 2,
        key: "CLAIMED",
        label: "Claimed"
    },
    "CANCELED": {
        id: 3,
        key: "CANCELED",
        label: "Canceled"
    },
};
