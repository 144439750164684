import React from 'react';
import Typography from '@mui/material/Typography';
import { Row, Col } from 'reactstrap';

function Footer() {

  return (
    <Row className="footer mt-5" style={{ zIndex: 1220, position: 'relative' }}>
      <Col md="12" className="text-center pt-3 pb-3">
        <Typography>
          &copy; {new Date().getFullYear()} Upcycle4Good
        </Typography>
      </Col>
    </Row>
  );
}

export default Footer