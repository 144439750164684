import React from 'react';
import Grid from '@mui/material/Grid';

import ProductCard from './productCard';
import logo from '../../static/image-placeholder.png';

function ProductList({ products, refetch }) {
    return (
        <Grid container spacing={3}>
            {products.map((product, index) =>
                <Grid item xs={12} md={6} lg={4} key={index}>
                    <ProductCard
                        name={product.name}
                        description={product.description}
                        imgURL={product.imageUrl == null ? logo : product.imageUrl}
                        publishedOn={product.createdAt}
                        statusName={product.statusName}
                        statusCode={product.statusCode}
                        id={product.id}
                        claimerUserId={product.claimerUser?.id || null}
                        donorUserId={product.donorUser?.id || null}
                        refetch={refetch}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default ProductList;