import React, { useContext } from 'react';
import { useMutation } from 'react-query';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Axios from 'axios';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { getAccessToken, USER_ROLES_IDS } from '../../../helpers/authHelpers';

function UserRow({ user, refetch, type }) {
    const accessToken = getAccessToken();

    const roleMutation = useMutation(((data) => {

        return Axios.put(`${process.env.REACT_APP_API_URL}/users/${user.userId}/roles/${data.typeId}`, 
            {
                "approved": data.approved,
                "typeId": data?.newTypeId || data.typeId
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }), {
        onSettled: () => {
            refetch();
        },
        onError: () => {
            // show error message
        },
    });

    const deleteMutation = useMutation((() => {
        return Axios.delete(`${process.env.REACT_APP_API_URL}/users/${user.userId}`, 
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }), {
        onSettled: () => {
            refetch();
        },
        onError: () => {
            // show error message
        },
    });

    const reactivateMutation = useMutation((() => {
        return Axios.patch(`${process.env.REACT_APP_API_URL}/users/${user.userId}/reactivate`, {},
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }), {
        onSettled: () => {
            refetch();
        },
        onError: () => {
            // show error message
        },
    });

    const handleSubmit = (approved, typeId) => {
        roleMutation.mutate({approved, typeId});
    };

    const handleRoleChange = (approved, currentRole, newRole) => {
        roleMutation.mutate({
            approved: approved,
            typeId: currentRole,
            newTypeId: newRole,
        });
    };

    return (
        <TableRow>
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.lastName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.phoneNum}</TableCell>
            <TableCell>{user.organization}</TableCell>
            <TableCell>
                {type === 'pending' ? (
                    <Stack direction="row" spacing={1}>
                        {user?.roles.map((role) => (
                            <Chip key={role?.typeId} variant="outlined" label={USER_ROLES_IDS[role?.typeId].label} />
                        ))}
                    </Stack>
                ) : (
                    <FormControl variant="standard">
                        {user?.roles.map((role) => (
                            <Select
                                value={USER_ROLES_IDS[role?.typeId].key}
                                onChange={(e) => handleRoleChange( Boolean(role?.approvedAt), role?.typeId, e.target.value)}
                                style={{ fontSize: 15 }}
                                key={role?.typeId}
                                >
                                    {Object.keys(USER_ROLES_IDS).map((key) => (
                                        <MenuItem key={USER_ROLES_IDS[key].key} value={USER_ROLES_IDS[key].key}>
                                            {USER_ROLES_IDS[key].label}
                                        </MenuItem>
                                    ))}
                                </Select>
                        ))}
                    </FormControl>
                )}  
            </TableCell>
            <TableCell style={{ minWidth: 120 }}>
                {(type === 'pending' || type === 'denied') && (
                    user?.roles.map((role) => (
                        !role?.approvedAt && (
                            <span key={role?.typeId}>
                                <ButtonGroup size="small" style={{ display: 'block', marginTop: 5, marginBottom: 5 }}>
                                    {type === 'pending' && (
                                        <Tooltip title="Deny" placement="top" key="deny">
                                            <Button key="deny" color="error" onClick={() => handleSubmit(false, role?.typeId)}>
                                                <PersonRemoveIcon color="error" />
                                            </Button>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Approve" placement="top" key="approve">
                                        <Button key="approve" color="success" onClick={() => handleSubmit(true, role?.typeId)}>
                                            <HowToRegIcon color="success" />
                                        </Button>
                                    </Tooltip>
                                </ButtonGroup>
                                {roleMutation.isLoading && (
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress />
                                    </Box>
                                )}
                            </span>
                        )
                    ))
                )}
                {type === "inactive" && (
                    <>
                        <ButtonGroup size="small" style={{ display: 'block', marginTop: 5, marginBottom: 5 }}>
                            <Tooltip title="Reactivate" placement="top" key="reactivate">
                                <Button key="approve" color="success" onClick={() => reactivateMutation.mutate()}>
                                    <HowToRegIcon color="success" />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                        {reactivateMutation.isLoading && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        )}
                    </>
                )}
                {type === "approved" && (
                    <>
                        <ButtonGroup size="small" style={{ display: 'block', marginTop: 5, marginBottom: 5 }}>
                            <Tooltip title="Delete" placement="top" key="delete">
                                <Button key="delete" color="error" onClick={() => deleteMutation.mutate()}>
                                    <PersonRemoveIcon color="error" />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                        {deleteMutation.isLoading && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        )}
                    </>
                )}
            </TableCell>
        </TableRow>
    );
};

export default UserRow;
