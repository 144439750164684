import React, { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DateTimePicker from 'react-datetime-picker';
import { Row, Col } from 'reactstrap';
import {
    Button,
    MenuItem,
    FormControlLabel,
    DialogActions,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DropzoneArea } from 'material-ui-dropzone';
import { Dialog, DialogContent, DialogTitle, Fab, Tooltip } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '@mui/material/Snackbar';
import { getAccessToken, getUserId } from '../../../helpers/authHelpers';

import { uploadFile } from '../../../services/firebaseStorage';
import UserContext from '../../../contexts/UserContext';
import Alert from "@mui/material/Alert";

const CATEGORY_URI = `${process.env.REACT_APP_API_URL}/products/categories`
const ADD_URI = `${process.env.REACT_APP_API_URL}/products`

/*
const validate = values => {
    const errors = {};
    if (!values.productName) {
        errors.productName = 'Required';
    }
    if (!values.productDescription) {
        errors.productDescription = 'Required';
    }
    if (!values.location) {
        errors.location = 'Required';
    }
    if (!values.category) {
        errors.category = 'Required';
    }
    return errors;
};
*/

const style = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 1230,
};

export default function NewProduct() {

    const [user] = useContext(UserContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [location, setLocation] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [marketValue, setMarketValue] = useState(0);
    const [stock, setStock] = useState(1);
    const [isTimebound, setIsTimebound] = useState(false);
    const [isShippingAvailable, setIsShippingAvailable] = useState(false);
    const [pickupDeadline, setPickupDeadline] = useState(new Date());
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [alertId, setAlertId] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [isLoading, setIsLoading] = useState('');

    const accessToken = getAccessToken();

    const loadCategories = () => {
        setIsLoading(true);
        fetch(CATEGORY_URI)
            .then(res => res.json())
            .then((result) => {
                setCategories(result);
                setIsLoading(false);
            },
            (error) => {
                console.error(error);
                setIsLoading(false);
            });
        
    }

    useEffect(() => {
        loadCategories();
    }, []);

    const handleReset = () => {
        setName(null);
        setDescription(null);
        setLocation(null);
        setImageUrl(''); // remove uploaded image too
        setMarketValue(null);
        setStock(null);
        setIsShippingAvailable(false);
        setIsTimebound(false);
        setPickupDeadline(new Date());
        setCategoryId(null);
    };

    const addImages = async (files) => {
        const url = await uploadFile(files[0]);
        setImageUrl(url);
    };

    const formatDate = date => {
        const formattedDate = `${date.getMonth()+1}-${date.getDate()}-${date.getFullYear()}`;
        setPickupDeadline(formattedDate);
    }

    const handleSubmit = event => {
        event.preventDefault();

        const data = {
            categoryId: categoryId,
            pickupDeadline: isTimebound ? `${pickupDeadline.getFullYear()}-${pickupDeadline.getMonth()+1}-` +
                `${pickupDeadline.getDate()} ${pickupDeadline.getHours()}:${pickupDeadline.getMinutes()}:` +
                `${pickupDeadline.getSeconds()}` : null,
            description: description,
            location: location,
            marketValue: marketValue,
            name: name,
            shippingAvailable: isShippingAvailable,
            stock: stock,
            imageUrl: imageUrl
        }

        console.log('sending');
        console.log(data);

        fetch(ADD_URI, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${accessToken}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    console.log(`${name} was posted successfully.`);
                    setSuccessOpen(true);
                    setModalOpen(false);
                } else {
                    console.log(`There was an error posting ${name}`);
                    setErrorOpen(true);
                }
            })
            .catch(error => console.error("Error", error));
    };

    function minMaxLength(text, minLength, maxLength) {
        let result = text.length < minLength;
        if(maxLength)
            result = result || text.length > maxLength;
        return !result;
    }

    function handleChange (e){
        const { name, value } = e.target;

        switch (name) {
            case 'name':
                if (!value) {
                    setAlertMessage("Name cannot be blank")
                    setAlertId('name')
                } else if (!minMaxLength(value, 0, 50)) {
                    setAlertMessage("Name cannot exceed 50 characters")
                    setAlertId('name')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    if (value === '') {
                        setName(null);
                    }
                }
                break;
            case 'marketValue':
                if (!value) {
                    setAlertMessage("Value cannot be blank")
                    setAlertId('marketValue')
                } else if (!minMaxLength(value, 0, 10)) {
                    setAlertMessage("Value cannot exceed 10 characters")
                    setAlertId('marketValue')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    if (value === '') {
                        setMarketValue(null);
                    }
                }
                break;
            case 'stock':
                if (!value) {
                    setAlertMessage("Quantity cannot be blank")
                    setAlertId('stock')
                } else if (!minMaxLength(value, 0, 10)) {
                    setAlertMessage("Quantity cannot exceed 10 characters")
                    setAlertId('stock')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    if (value === '') {
                        setStock(null);
                    }
                }
                break;
            case 'description':
                if (!minMaxLength(value, 0, 600)) {
                    setAlertMessage("Description cannot exceed 600 characters")
                    setAlertId('description')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                }
                break;
            case 'location':
                if (!value) {
                    setAlertMessage("Location cannot be blank")
                    setAlertId('location')
                } else if (!minMaxLength(value, 0, 100)) {
                    setAlertMessage("Location cannot exceed 100 characters")
                    setAlertId('location')
                } else {
                    setAlertMessage(null)
                    setAlertId(null)
                    if (value === '') {
                        setLocation(null);
                    }
                }
                break;
            default:
                break;
        }
    }

    function validProduct() {

        // required values must be filled out
        if (name == null || marketValue == null || stock == null || location == null) {
            return false;
        }

        // all fields must be valid
        if (alertMessage) {
            return false;
        }

        return true;
    }


    return (
        isLoading ? (
            <div>
                <Row>
                    <Col className="text-center">
                        <CircularProgress style={{ 'color': 'rgb(7, 77, 130)' }} />
                    </Col>
                </Row>
            </div>
        ) : (
            <div>
                <Snackbar open={false} autoHideDuration={4000} onClose={() => {}} message="Item posted successfully!" />
                <Tooltip title="Add a Product" aria-label="add">
                    <Fab color="primary" aria-label="add" style={style} onClick={() => setModalOpen(true)}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                <Dialog open={modalOpen} onClose={handleReset} aria-labelledby="product-upload-form-dialog">
                    <DialogTitle id="product-upload-form-dialog">Add a Product</DialogTitle>
                    <DialogContent>
                        <FormControl style={{ width: "100%" }}>
                            <Row>
                                <DropzoneArea
                                    acceptedFiles={['image/*']}
                                    dropzoneText={"Drag and drop an image here or click to select."}
                                    onChange={addImages}
                                    filesLimit={1}
                                />
                            </Row>
                            <Row className="mt-2">
                                <Col md="12" lg="7">
                                    <TextField
                                        name="name"
                                        required
                                        style={{ width: "100%", marginTop: 5 }}
                                        id="productName"
                                        label="Product Name"
                                        error={alertId === "name"}
                                        onBlur={handleChange}
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                    />
                                </Col>
                                <Col md="7" lg="3">
                                    <TextField
                                        name="marketValue"
                                        required
                                        type="number"
                                        style={{ width: "115%", marginLeft: -16, marginTop: 5 }}
                                        id="marketValue"
                                        label="Value ($)"
                                        error={alertId === "marketValue"}
                                        onBlur={handleChange}
                                        onChange={(e) => setMarketValue(e.target.value)}
                                        value={marketValue}
                                    />
                                </Col>
                                <Col md="5" lg="2">
                                    <TextField
                                        name="stock"
                                        type="number"
                                        required
                                        style={{ width: "125%", marginLeft: -16, marginTop: 5 }}
                                        id="stock"
                                        label="Quantity"
                                        error={alertId === "stock"}
                                        onBlur={handleChange}
                                        onChange={(e) => setStock(e.target.value)}
                                        value={stock}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <TextField
                                        name="description"
                                        fullWidth
                                        style={{ marginBottom: 5, marginTop: 5 }}
                                        id="productDesc"
                                        label="Product Description"
                                        error={alertId === "description"}
                                        onBlur={handleChange}
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormControl style={{ width: "100%" }}>
                                        <InputLabel id="category-label">Product Category</InputLabel>
                                        <Select
                                            label="Product Category"
                                            labelId="category-label"
                                            id="category"
                                            fullWidth
                                            name="status"
                                            style={{ marginBottom: 5 }}
                                            onChange={(e) => setCategoryId(e.target.value)}
                                            value={categoryId}
                                        >
                                            {
                                                Object.entries(categories).map(([index, category]) => {
                                                    return <MenuItem value={category.id}>{category.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isShippingAvailable}
                                                name="isShippingAvailable" 
                                                onChange={(e) => setIsShippingAvailable(!isShippingAvailable)}
                                                value={isShippingAvailable}
                                            />
                                        }
                                        label="Shipping Available"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <TextField
                                        autoComplete="ploc"
                                        name="location"
                                        required
                                        fullWidth
                                        id="productLoc"
                                        label="Product Location"
                                        style={{ marginBottom: 5 }}
                                        error={alertId === "location"}
                                        onBlur={handleChange}
                                        onChange={(e) => setLocation(e.target.value)}
                                        value={location}
                                    />
                                </Col>
                                <Col>
                                    <FormControlLabel
                                        control={<Checkbox checked={isTimebound}
                                        onChange={(e) => setIsTimebound(!isTimebound)}
                                        name="isTimebound"
                                        value={isTimebound} />}
                                        label="Is there a pickup deadline?"
                                    />
                                </Col>
                            </Row>
                            {isTimebound && (
                                <Row id="isAvailable" className="mt-2">
                                    <Col xs="2" className="text-left">
                                        <p>Available until:</p>
                                    </Col>
                                    <Col xs="10">
                                        <DateTimePicker
                                            onChange={setPickupDeadline}
                                            value={pickupDeadline}
                                            name="pickupDeadline"
                                        />
                                    </Col>
                                </Row>
                            )}
                        </FormControl>
                    </DialogContent>
                    <Row className="mt-2">
                        <DialogActions style={{ flexGrow: "1" }}>
                            <Col xs="6" md="3">
                                <Button
                                    fullWidth
                                    variant="text"
                                    onClick={() => setModalOpen(false)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col xs="6" md="3">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ marginLeft: -15 }}
                                    disabled={!validProduct()}
                                    onClick={handleSubmit}
                                >
                                    Publish
                                </Button>
                            </Col>
                        </DialogActions>
                    </Row>
                    <Snackbar open={alertId}>
                        <Alert severity="error" sx={{width: '100%'}}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={errorOpen} autoHideDuration={3000} onClose={() => setErrorOpen(false)}>
                        <Alert severity="error" sx={{width: '100%'}}>
                            There was an error, please try again.
                        </Alert>
                    </Snackbar>
                </Dialog>
                <Snackbar open={successOpen} autoHideDuration={3000} onClose={() => setSuccessOpen(false)}>
                    <Alert severity="success" sx={{width: '100%'}}>
                        Product added successfully!
                    </Alert>
                </Snackbar>
            </div>
        )
    );
};
